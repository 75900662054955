import {
  DIALOG_APPROVE_ORDERS,
  DIALOG_REJECT_ORDERS,
  DIALOG_ARCHIVE_ORDERS,
  DIALOG_ACTIVATE_ORDERS,
  DIALOG_DELETE_ORDERS,
  DIALOG_FINALIZE_ORDERS,
  DIALOG_ASSIGN_ORDERS,
  DIALOG_EXECUTE_LOCATES,
  DIALOG_CHECK_EXPENSIVE_BORROW,
  // DIALOG_EXECUTE_ORDERS,
  // DIALOG_EXECUTE_ROUTES,
  // DIALOG_EXECUTE_ROUTES_MODE,
  DIALOG_VIEW_ORDER_CHANGES,
  DIALOG_VIEW_HOLDINGS,
  DIALOG_REPORT_SHOW,
  DIALOG_VIEW_IPO_LIST,
  DIALOG_RUN_COMPLIANCE,
  // DIALOG_QUANT_ORDERS,
  OMS_VIEW_MODE_HIST,
  OMS_VIEW_MODE_LIVE,
  DIALOG_VIEW_RULES,
  DIALOG_ACK_ROUTE_ERRORS,
  DIALOG_OVERRIDE_CPL,
  DIALOG_ENRICH_ORDERS,
  DIALOG_MANUAL_LOCATES,
  DIALOG_CONTRACT_ROLLOVER,
  DIALOG_TXN_LIMITS,
  DIALOG_BROKERS_INFO,
  DIALOG_SPLIT_ORDERS,
  DIALOG_MARGIN_EXECUTE_LOCATES,
  DIALOG_RECALL
} from '../../omsConstants';
import config from 'common/config';

const hideBtnBySystemFn = showEnvs => {
  return !(showEnvs && showEnvs.includes(config.system));
};

const defaultDisabledFn = ({ viewMode, orders }) => {
  return viewMode === OMS_VIEW_MODE_HIST || orders.length === 0;
};

const activateBtnDisableFn = ({ viewMode, orders }) => {
  return viewMode === OMS_VIEW_MODE_LIVE || orders.length === 0;
};

const histDisabledFn = ({ viewMode }) => {
  return viewMode === OMS_VIEW_MODE_HIST;
};

const operations = [
  {
    key: 'preTrade',
    subOps: [
      {
        key: 'approve',
        header: 'Approve',
        color: 'green',
        dialogCode: DIALOG_APPROVE_ORDERS,
        disabledFn: defaultDisabledFn
      },
      {
        key: 'reject',
        header: 'Reject',
        color: 'red',
        dialogCode: DIALOG_REJECT_ORDERS,
        disabledFn: defaultDisabledFn
      },
      {
        key: 'splitOrders',
        header: 'Split',
        color: 'green',
        dialogCode: DIALOG_SPLIT_ORDERS,
        disabledFn: defaultDisabledFn,
        menuOnly: true
      },
      {
        key: 'viewChanges',
        header: 'Changes',
        color: 'blue',
        dialogCode: DIALOG_VIEW_ORDER_CHANGES,
        disabledFn: ({ orders }) => orders.length === 0
      }
    ]
  },
  {
    key: 'rules',
    subOps: [
      {
        key: 'viewRules',
        header: 'Rules',
        color: 'blue',
        dialogCode: DIALOG_VIEW_RULES,
        disabledFn: () => false,
        hideFn: ({ requestType }) => requestType === 'IPO'
      },
      {
        key: 'txnLimits',
        header: 'Txn Limit',
        color: 'blue',
        dialogCode: DIALOG_TXN_LIMITS,
        disabledFn: () => false,
        hideFn: ({ requestType }) => requestType === 'IPO'
      },
      {
        key: 'brokersInfo',
        header: 'Broker',
        color: 'blue',
        dialogCode: DIALOG_BROKERS_INFO,
        disabledFn: () => false,
        hideFn: ({ requestType }) => requestType === 'IPO'
      }
    ]
  },
  {
    key: 'check&info',
    subOps: [
      {
        key: 'assign',
        header: 'Assign',
        color: 'green',
        dialogCode: DIALOG_ASSIGN_ORDERS,
        disabledFn: defaultDisabledFn
      },
      {
        key: 'viewHoldings',
        header: 'Holdings',
        color: 'blue',
        dialogCode: DIALOG_VIEW_HOLDINGS,
        disabledFn: () => false
      },
      {
        key: 'viewReports',
        header: 'Report',
        color: 'blue',
        dialogCode: DIALOG_REPORT_SHOW,
        disabledFn: () => false
      },
      {
        key: 'runCompliance',
        header: 'Run Compliance',
        color: 'green',
        dialogCode: DIALOG_RUN_COMPLIANCE,
        disabledFn: defaultDisabledFn,
        hideFn: ({ requestType }) => requestType === 'IPO'
      },
      {
        key: 'overrideCPL',
        header: 'Override CPL',
        color: 'green',
        dialogCode: DIALOG_OVERRIDE_CPL,
        disabledFn: defaultDisabledFn,
        menuOnly: true,
        hideFn: ({ requestType }) => requestType === 'IPO'
      },
      {
        key: 'enrichOrders',
        header: 'Enrich',
        color: 'green',
        dialogCode: DIALOG_ENRICH_ORDERS,
        disabledFn: defaultDisabledFn,
        menuOnly: true
      }
      // {
      //   key: 'quantOrders',
      //   header: 'Quant',
      //   color: 'green',
      //   dialogCode: DIALOG_QUANT_ORDERS,
      //   disabledFn: () => false
      // }
    ]
  },
  {
    key: 'ipo',
    subOps: [
      {
        key: 'viewIPOList',
        header: 'IPO List',
        color: 'blue',
        dialogCode: DIALOG_VIEW_IPO_LIST,
        disabledFn: () => false,
        hideFn: ({ requestType }) => requestType !== 'IPO'
      }
    ]
  },
  {
    key: 'execute',
    subOps: [
      {
        key: 'checkExpensiveBorrow',
        header: 'CR',
        color: 'brown',
        // dialogCode: DIALOG_EXECUTE_ROUTES,
        // disabledFn: defaultDisabledFn,
        // info: { mode: DIALOG_EXECUTE_ROUTES_MODE.EXECUTE },
        dialogCode: DIALOG_CHECK_EXPENSIVE_BORROW,
        disabledFn: histDisabledFn,
        hideFn: ({ requestType }) => requestType === 'IPO'
      },
      {
        key: 'recall',
        header: 'Recall',
        color: 'brown',
        dialogCode: DIALOG_RECALL,
        disabledFn: histDisabledFn,
        hideFn: ({ requestType }) => requestType === 'IPO'
      },
      {
        key: 'executeLocates',
        header: 'Locates(A)',
        color: 'brown',
        // dialogCode: DIALOG_EXECUTE_ROUTES,
        // disabledFn: defaultDisabledFn,
        // info: { mode: DIALOG_EXECUTE_ROUTES_MODE.EXECUTE },
        dialogCode: DIALOG_EXECUTE_LOCATES,
        disabledFn: histDisabledFn,
        hideFn: ({ requestType }) => requestType === 'IPO'
      },
      {
        key: 'contractLocates',
        header: 'Locates(C)',
        color: 'brown',
        // dialogCode: DIALOG_EXECUTE_ROUTES,
        // disabledFn: defaultDisabledFn,
        // info: { mode: DIALOG_EXECUTE_ROUTES_MODE.EXECUTE },
        dialogCode: DIALOG_MARGIN_EXECUTE_LOCATES,
        disabledFn: defaultDisabledFn,
        hideFn: ({ requestType }) =>
          requestType === 'IPO' || hideBtnBySystemFn(['ONSHORE'])
      },
      {
        key: 'manualLocates',
        header: 'Locates(M)',
        color: 'brown',
        // dialogCode: DIALOG_EXECUTE_ROUTES,
        // disabledFn: defaultDisabledFn,
        // info: { mode: DIALOG_EXECUTE_ROUTES_MODE.EXECUTE },
        dialogCode: DIALOG_MANUAL_LOCATES,
        disabledFn: defaultDisabledFn,
        hideFn: ({ requestType }) => requestType === 'IPO'
      },
      {
        key: 'contractRollover',
        header: 'Rollover',
        color: 'brown',
        dialogCode: DIALOG_CONTRACT_ROLLOVER,
        disabledFn: histDisabledFn,
        hideFn: ({ requestType }) =>
          requestType === 'IPO' || hideBtnBySystemFn(['ONSHORE'])
      }
    ]
  },
  {
    key: 'postTrade',
    subOps: [
      {
        key: 'finalize',
        header: 'Finalize',
        color: 'green',
        dialogCode: DIALOG_FINALIZE_ORDERS,
        disabledFn: defaultDisabledFn
      },
      {
        key: 'archive',
        header: 'Archive',
        color: 'green',
        dialogCode: DIALOG_ARCHIVE_ORDERS,
        disabledFn: defaultDisabledFn
      }
    ]
  },
  {
    key: 'troubleshoot',
    subOps: [
      {
        key: 'activate',
        header: 'Activate',
        color: 'green',
        dialogCode: DIALOG_ACTIVATE_ORDERS,
        disabledFn: activateBtnDisableFn,
        hideFn: ({ viewMode }) => viewMode === OMS_VIEW_MODE_LIVE
      },
      {
        key: 'ackErrors',
        header: 'Ack Errors',
        color: 'green',
        dialogCode: DIALOG_ACK_ROUTE_ERRORS,
        disabledFn: defaultDisabledFn,
        menuOnly: true,
        hideFn: ({ requestType }) => requestType === 'IPO'
      },
      {
        key: 'delete',
        header: 'Delete',
        color: 'red',
        dialogCode: DIALOG_DELETE_ORDERS,
        disabledFn: defaultDisabledFn
      }
    ]
  }
];

export default operations;
