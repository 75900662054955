import React, { Component } from 'react';
import { Modal, Button, Spin, Tag } from 'antd';
import { Form, Input, Message, Select } from 'semantic-ui-react';
import _ from 'lodash';
import Trade from '../../entities/Trade';
import client from '../../api/client';
import TradeCalculator from '../../entities/calculators/TradeCalculator';
import TradeValidator from '../../entities/validators/TradeValidator';
import { DIALOG_EXECUTE_MULTI_TRADES } from '../../orderConstants';
import TickerSearchField from 'common/ui/TickerSearchField';
import numeral from 'numeral';
import Options from '../../entities/Options';
import {
  isInternalFund,
  isPaperTradeFund
} from '../../../../common/utils/DomainUtils';
import { subTradeGridColumns } from './GridColumnMap';
import { HotTable } from '@handsontable/react';
import hotTableUtils from 'common/ui/hotTableUtils';
import { isAdminRole, isWBook, roleTradeLabel } from 'common/utils/DomainUtils';

const BOOK_W = 'W';

class MultiTradesDialog extends Component {
  state = {
    isInitialized: false,

    submitStatus: 'READY',
    serverErrMsg: '',
    verificationErrMsgs: [],
    verificationWarningMsgs: [],
    fields: Trade.emptyForm(),
    subTrades: [],

    isLoadingSecurity: false,
    security: null,

    liveRiskInfoMap: {},

    gridWrapperStyle: {
      width: '100%',
      height: '150px',
      marginTop: '20px'
    },
    hotTableSettings: hotTableUtils.createSettings({
      columns: subTradeGridColumns,
      contextMenu: ['remove_row']
    })
  };

  constructor(props) {
    super(props);

    this.hotTblRef = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // No re-rendering if props is updated.
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  componentDidMount() {
    this._debouncedSubmit = _.debounce(this.onFormSubmit, 500);

    const {
      info,
      liveRiskInfos,
      settings: { user = {}, funds }
    } = this.props;
    const liveRiskInfoMap = liveRiskInfos
      .filter(r => !['PMSF', 'VCC', 'CVF', 'DCL'].includes(r.fundCode))
      .reduce((acc, curr) => {
        const key = `${curr.fundCode}-${curr.bookCode}`;
        acc[key] = curr;
        return acc;
      }, {});

    const sortFn = bookCode => {
      if (bookCode.charAt(0) === 'W') {
        return `1_${bookCode}`;
      } else {
        return `2_${bookCode}`;
      }
    };

    const fundBookCodes = Object.values(liveRiskInfoMap)
      .filter(v => v.fundCode !== v.bookCode)
      .map(v => {
        const fundBooks = funds
          ? funds.filter(r => r.name === v.fundCode)
          : null;
        const filterbooks = !_.isEmpty(fundBooks)
          ? fundBooks[0].books.filter(r => r.enableTxn && r.name === v.bookCode)
          : [];
        if (_.isEmpty(filterbooks)) return null;
        return { fundCode: v.fundCode, bookCode: v.bookCode };
      })
      .filter(r => r);
    const fundBookByFund = _.groupBy(fundBookCodes, fb => fb.fundCode);
    const distinctBookCodes = [
      ...new Set(fundBookCodes.map(fb => fb.bookCode))
    ];
    const bookCodes = _.orderBy(
      [
        ...(distinctBookCodes.some(b => b.charAt(0) === 'W') &&
        isAdminRole(user.role)
          ? [BOOK_W]
          : []),
        ...distinctBookCodes
      ],
      [sortFn]
    );

    this.setState({
      bookCodes,
      fundBookByFund,
      liveRiskInfoMap
    });

    if (info.createNew) {
      this._initForNew(_.first(bookCodes));
    }
  }

  _generateSubTrades(fields, security) {
    if (!security) return [];

    const { liveRiskInfoMap } = this.state;
    const { bookCode } = fields;
    let subTrades = Object.entries(liveRiskInfoMap)
      .filter(([k, _]) => k.includes(`-${bookCode}`))
      .map(([k, v]) => {
        return {
          key: v.fundCode,
          fundCode: v.fundCode,
          bookCode: v.bookCode,

          posnStartPct: 0,
          posnEndPct: 0,

          quantity: 0,
          qtyUsd: 0,
          qtyPct: 0,
          qtyInput: ''
        };
      });

    if (bookCode === BOOK_W) {
      const subTradesByFund = _.groupBy(
        subTrades.filter(st => isWBook(st.bookCode)),
        st => st.fundCode
      );
      const postionsByFund = _.groupBy(
        Object.values(security.positions).filter(p =>
          p.bookCode.startsWith(bookCode)
        ),
        p => p.fundCode
      );
      subTrades = Object.entries(subTradesByFund).map(([fundCode, g]) => {
        const position = _.first(
          (postionsByFund[fundCode] || []).filter(p => isWBook(p.bookCode))
        );
        return position
          ? g.find(
              st =>
                st.fundCode === position.fundCode &&
                st.bookCode === position.bookCode
            )
          : _.first(_.orderBy(g, ['bookCode']));
      });
    }

    subTrades = this._calcSubTrades(
      fields,
      security,
      subTrades,
      'posnEndPct',
      fields.posnEndPct
    );

    return TradeCalculator.allocateInlinePct(subTrades, fields.inlinePct);
  }

  _calcSubTrades(fields, security, subTrades, name, value) {
    const { liveRiskInfoMap } = this.state;
    const { fundBooks } = this.props.settings;
    const qtyInput = TradeCalculator.getQtyInputByFieldName(name);

    return subTrades.map(st => {
      const extraFields = TradeCalculator.calcAll(
        {
          ...fields,
          ...st,
          qtyInput,
          [name]: value
        },
        security,
        liveRiskInfoMap,
        fundBooks
      );

      return {
        ...st,
        ...extraFields
      };
    });
  }

  _invokeInitialization(fields) {
    this.setState({
      fields,
      isInitialized: true
    });
  }

  _initForNew(defaultBookCode) {
    const {
      info: { defaultFields = {} },
      settings: { preference, funds }
    } = this.props;

    const firstFund = _.first(funds);

    const timeInForce = TradeCalculator.calcTimeInForce(
      firstFund.name,
      preference.timeInForce
    );
    const algoCode = TradeCalculator.calcAlgo(preference.algoCode);
    const orderType = TradeCalculator.calcOrderType(preference.orderType);

    const fields = {
      ...this.state.fields,
      //   fundCode: firstFund.name,
      bookCode: defaultBookCode,
      ...defaultFields,
      timeInForce,
      algoCode,

      inlinePct: ['T13'].includes(defaultBookCode) ? 36 : 20,
      posnEndPct: 0,
      orderType
    };

    this._invokeInitialization(fields);
  }

  _aggregateSubTradeErrors = (prev, stErrors) => {
    const { key, errors } = stErrors;
    return Object.entries(errors)
      .filter(([_, err]) => err && !err.isWarning)
      .reduce((acc, curr) => {
        const [field, err] = curr;
        const keys = acc[err.msg] || [];
        return {
          ...acc,
          [err.msg]: ['quantity', 'side'].includes(field) ? [...keys, key] : []
        };
      }, prev);
  };

  _aggregateSubTradeWarnings = (prev, stErrors) => {
    const { key, errors } = stErrors;
    return Object.entries(errors)
      .filter(([_, err]) => err && err.isWarning)
      .reduce((acc, curr) => {
        const [field, err] = curr;
        const keys = acc[err.msg] || [];
        return {
          ...acc,
          [err.msg]: ['quantity', 'side'].includes(field) ? [...keys, key] : []
        };
      }, prev);
  };

  _validateSubTrades = (fields, security, subTrades) => {
    const { liveRiskInfoMap } = this.state;
    const { fundBooks, user } = this.props.settings;

    const validateResult = subTrades
      .filter(st => st.quantity)
      .map(st => {
        const errors = TradeValidator.validate(
          { ...fields, ...st },
          security,
          liveRiskInfoMap,
          '',
          fundBooks,
          user
        );

        return {
          key: st.key,
          errors
        };
      });
    const allErrorMap = validateResult.reduce((acc, stErrors) => {
      return this._aggregateSubTradeErrors(acc, stErrors);
    }, {});

    const allWaringMap = validateResult.reduce((acc, stErrors) => {
      return this._aggregateSubTradeWarnings(acc, stErrors);
    }, {});

    const verificationErrMsgs = Object.entries(allErrorMap).map(([err, keys]) =>
      keys.length > 0 ? `[${keys.join(',')}]: ${err}` : `${err}`
    );
    const verificationWarningMsgs = Object.entries(allWaringMap).map(
      ([err, keys]) =>
        keys.length > 0 ? `[${keys.join(',')}]: ${err}` : `${err}`
    );
    return {
      verificationErrMsgs,
      verificationWarningMsgs
    };
  };

  _doCalcAfterGetSecurity = security => {
    if (!security) {
      this._assertSecurityError();
      return;
    }

    this.setState((prevState, props) => {
      const { fields } = prevState;
      const {
        info: { createNew }
      } = props;

      const updatedFields = {
        ...fields,
        ticker: createNew ? security.ticker : fields.ticker,
        lotSize: (security && security.lotSize) || fields.lotSize || 1,
        limitPriceLocal: createNew
          ? (security && security.price) || 0
          : fields.limitPriceLocal
      };

      const subTrades = this._generateSubTrades(fields, security);

      // Do the validation.
      const {
        verificationErrMsgs,
        verificationWarningMsgs
      } = this._validateSubTrades(updatedFields, security, subTrades);

      return {
        isLoadingSecurity: false,
        security,
        fields: updatedFields,
        verificationErrMsgs,
        verificationWarningMsgs,
        subTrades
      };
    });
  };

  _assertSecurityError = () => {
    const { fields } = this.state;
    const { fundBooks, user } = this.props.settings;

    const fieldErrors = TradeValidator.validate(
      fields,
      null,
      null,
      'ticker',
      fundBooks,
      user
    );
    this.setState({
      isLoadingSecurity: false,
      security: null,
      verificationErrMsgs: [fieldErrors['ticker'].msg],
      subTrades: []
    });
  };

  _getSecurity = ticker => {
    if (!ticker) {
      this._assertSecurityError();
    }

    this.setState({ isLoadingSecurity: true });

    client
      .getSecurities([{ ticker }])
      .then(([security]) => {
        this._doCalcAfterGetSecurity(security);
      })
      .catch(_ => {
        this._assertSecurityError();
      });
  };

  onTickerSelect = ticker => {
    this._getSecurity(ticker);
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_EXECUTE_MULTI_TRADES);
  };

  onFormSubmit = () => {
    const { fields, security, subTrades } = this.state;

    // Validate data before submitting trade.
    const {
      verificationErrMsgs,
      verificationWarningMsgs
    } = this._validateSubTrades(fields, security, subTrades);
    if (verificationErrMsgs.length) {
      this.setState({
        verificationErrMsgs,
        verificationWarningMsgs
      });
      return;
    }

    const trades = subTrades
      .filter(st => st.quantity > 0)
      .map(st => {
        const pmRemark =
          fields.algoCode === 'INLINE'
            ? [`${st.inlinePct}%`, fields.pmRemark].filter(Boolean).join(' ')
            : fields.pmRemark;
        const qtyInput = st.qtyInput === 'ByPosPct' ? 'ByPct' : st.qtyInput;

        const mergedFields = {
          ...fields,
          ...st,
          pmRemark,
          qtyInput
        };

        return Trade.toModel(mergedFields, security, false);
      });

    const { submitDialogSuccess, info } = this.props;

    this.setState({ submitStatus: 'SUBMITTING' });
    client
      .addTrades(trades)
      .then(result => {
        if (info.createNew) {
          _.zip(trades, result).forEach(([t, r]) => {
            t.tradeId = r.id;
            t.refId = r.refId;
          });
        }

        submitDialogSuccess(DIALOG_EXECUTE_MULTI_TRADES, {
          trades,
          createNew: info.createNew
        });
        this.setState({ submitStatus: 'READY' });
      })
      .catch(_ => {
        this.setState({
          submitStatus: 'ERROR',
          serverErrMsg: 'Server Error! Please contact system administrator.'
        });
      });
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus } = this.state;
    return {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      )
    }[submitStatus];
  };

  _changeInput = ({ name, value }) => {
    this.setState((prevState, props) => {
      const { fields, security } = prevState;

      const updatedFields = {
        ...fields,
        [name]: value
      };

      let subTrades = prevState.subTrades;
      if (['bookCode'].includes(name)) {
        subTrades = this._generateSubTrades(updatedFields, security);
      } else if (['posnEndPct', 'limitPriceLocal'].includes(name)) {
        subTrades = this._calcSubTrades(
          updatedFields,
          security,
          subTrades,
          'posnEndPct',
          updatedFields.posnEndPct
        );
      }

      if (['inlinePct', 'posnEndPct'].includes(name)) {
        subTrades = TradeCalculator.allocateInlinePct(
          subTrades,
          updatedFields.inlinePct
        );
      }

      // Do the validation.
      const {
        verificationErrMsgs,
        verificationWarningMsgs
      } = this._validateSubTrades(updatedFields, security, subTrades);

      return {
        fields: updatedFields,
        subTrades,
        verificationErrMsgs,
        verificationWarningMsgs
      };
    });
  };

  onInputChange = ({ name, value }) => {
    this._changeInput({
      name,
      value
    });
  };

  _beforeCellChange = (changes, source) => {
    const { fields, security } = this.state;
    let { subTrades } = this.state;
    const _parseValue = (field, v) =>
      ['quantity', 'qtyUsd'].includes(field)
        ? parseInt(v, 10)
        : ['qtyPct', 'posnEndPct'].includes(field)
        ? parseFloat(v)
        : v;

    subTrades = changes.reduce((prev, c) => {
      const [row, field, oldValue, newValue] = c;
      const oldParsedValue = _parseValue(field, oldValue);
      const newParsedValue = _parseValue(field, newValue);
      if (oldParsedValue === newParsedValue) return prev;

      const qtyInput = TradeCalculator.getQtyInputByFieldName(field);

      return prev.map((st, i) => {
        if (i === row) {
          const changedField = qtyInput
            ? field
            : field === 'side'
            ? 'qtyPct'
            : 'posnEndPct';
          const updatedSubTrade = { ...st, [field]: newParsedValue };

          if (qtyInput || ['bookCode', 'side'].includes(field)) {
            const [subTrade] = this._calcSubTrades(
              fields,
              security,
              [updatedSubTrade],
              changedField,
              updatedSubTrade[changedField]
            );
            return subTrade;
          }

          return updatedSubTrade;
        }
        return st;
      });
    }, subTrades);

    if (!changes.some(([, field, ,]) => field === 'inlinePct'))
      subTrades = TradeCalculator.allocateInlinePct(
        subTrades,
        fields.inlinePct
      );

    // Do the validation.
    const {
      verificationErrMsgs,
      verificationWarningMsgs
    } = this._validateSubTrades(fields, security, subTrades);

    this.setState({ subTrades, verificationErrMsgs, verificationWarningMsgs });

    return false;
  };

  _beforeRemoveRow = (row, count, rows, source) => {
    let { subTrades, fields } = this.state;
    subTrades = TradeCalculator.allocateInlinePct(
      subTrades.filter((_, i) => !rows.includes(i)),
      fields.inlinePct
    );

    this.setState({ subTrades });

    return false;
  };

  // _afterLoadData = () => {
  //   if (!this.hotTblRef.current) return;

  //   const {
  //     settings: { funds }
  //   } = this.props;

  //   const hotTbl = this.hotTblRef.current.hotInstance;
  //   const subTrades = hotTbl.getSourceData() || [];
  //   subTrades.forEach((st, row) => {
  //     const fund = funds.find(f => f.name === st.fundCode) || {};
  //     const source = fund.books.map(b => b.name);
  //     hotTbl.setCellMeta(row, hotTbl.propToCol('bookCode'), 'source', source);
  //   });
  // };

  _displaySecurityInfo = () => {
    const { security, subTrades } = this.state;

    if (security) {
      const { name, price, avgVolume, barraData = {} } = security;

      let advPct;
      if (avgVolume) {
        const allQty = _.sumBy(subTrades, st => st.quantity);
        const value = parseInt(allQty, 10) / avgVolume;
        advPct =
          Math.abs(value) < 0.0001 ? '0%' : numeral(value).format('0.00%');
      }

      const beta = barraData.predBeta
        ? numeral(barraData.predBeta).format('0.00')
        : 'N.A.';

      return `${name}  [Price: ${price ||
        'N.A.'}, Beta: ${beta}, ADV%: ${advPct || 'N.A.'}]`;
    }

    return null;
  };

  _diplayQtyDirection = () => {
    const {
      fields: { posnEndPct = 0 }
    } = this.state;

    const qtyDirection =
      posnEndPct > 0
        ? { value: 'LONG', color: '#87d068' }
        : posnEndPct < 0
        ? { value: 'SHORT', color: '#f50' }
        : { value: 'FLAT', color: '#2db7f5' };
    return (
      <Tag
        color={qtyDirection.color}
        style={{
          lineHeight: '35px',
          fontSize: '13px',
          marginRight: '0',
          marginLeft: '2px'
        }}
      >
        {qtyDirection.value}
      </Tag>
    );
  };

  _createForm = () => {
    const {
      liveRiskInfoMap,
      fields,
      isLoadingSecurity,
      security,
      bookCodes = [],
      verificationErrMsgs,
      verificationWarningMsgs,
      serverErrMsg
    } = this.state;

    const bookOptions = bookCodes.map(b => ({
      key: b,
      text: b,
      value: b
    }));

    const defaultFundCode = Object.values(liveRiskInfoMap)
      .filter(v => v.bookCode.includes(`-${fields.bookCode}`))
      .map(v => v.fundCode)
      .find(Boolean);

    const hideLimitPrice = fields.orderType === 'MKT';
    const hideInlinePct = fields.algoCode !== 'INLINE';
    const orderTypeOptions = Options.orderType.filter(
      o => !isPaperTradeFund(defaultFundCode) || o.key === 'MKT'
    );
    const isNewTrade = this.props.info.createNew;
    const isInternalUse = isInternalFund(defaultFundCode);

    const isOption = security && security.securityType2 === 'Option';

    const errorMsgs = [...verificationErrMsgs, serverErrMsg].filter(Boolean);
    const warningMsgs = [...verificationWarningMsgs].filter(Boolean);

    return (
      <Form>
        <Form.Select
          fluid
          disabled={!isNewTrade}
          options={bookOptions}
          value={fields.bookCode}
          placeholder="Select Book..."
          onChange={(e, { value }) =>
            this.onInputChange({ name: 'bookCode', value })
          }
        />
        <Form.Field>
          {!isNewTrade ? (
            <Input disabled value={fields.ticker} />
          ) : (
            <TickerSearchField
              name="ticker"
              value={fields.ticker}
              onChange={this.onInputChange}
              selectTicker={this.onTickerSelect}
            />
          )}
        </Form.Field>
        <Form.Input
          fluid
          placeholder="Name [Price, Beta, ADV%]"
          loading={isLoadingSecurity}
          value={this._displaySecurityInfo() || ''}
          readOnly
        />
        <Form.Group widths="equal">
          <Form.Select
            fluid
            options={orderTypeOptions}
            value={fields.orderType}
            placeholder="Select Order Type..."
            onChange={(e, { value }) =>
              this.onInputChange({ name: 'orderType', value })
            }
          />
          <Form.Field>
            <Input
              disabled={hideLimitPrice}
              labelPosition="left"
              placeholder="Enter Lmt Price..."
              type="text"
              value={fields.limitPriceLocal}
              onChange={(e, { value }) =>
                this.onInputChange({ name: 'limitPriceLocal', value })
              }
              label="Limit Px:"
            >
              {/* <Label basic>Limit:</Label>
              <input /> */}
            </Input>
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <Select
              fluid
              options={Options.algo}
              value={fields.algoCode}
              placeholder="Select Algo..."
              onChange={(e, { value }) =>
                this.onInputChange({ name: 'algoCode', value })
              }
              style={{ float: 'left' }}
            />
          </Form.Field>
          <Form.Field>
            <Input
              disabled={hideInlinePct}
              labelPosition="left"
              placeholder="Enter inline %..."
              type="text"
              value={fields.inlinePct}
              onChange={(e, { value }) =>
                this.onInputChange({ name: 'inlinePct', value })
              }
              label="Inline %:"
            ></Input>
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Select
            fluid
            disabled={!isInternalUse}
            options={Options.timeInForce}
            value={fields.timeInForce}
            placeholder="Select TIF..."
            onChange={(e, { value }) =>
              this.onInputChange({ name: 'timeInForce', value })
            }
          />
        </Form.Group>

        {isOption && (
          <Message info>
            For option, both USD value and AUM % input are based on underlying
            notnl value.
          </Message>
        )}

        <Form.Group widths="equal">
          <Form.Field>
            <div style={{ display: 'flex' }}>
              <Input
                disabled={!isNewTrade && !isInternalUse}
                labelPosition="left"
                placeholder="Enter Target %, positive means LONG, otherwise SHORT..."
                type="text"
                value={fields.posnEndPct}
                onChange={(e, { value }) =>
                  this.onInputChange({ name: 'posnEndPct', value })
                }
                label="Target %:"
              ></Input>

              {this._diplayQtyDirection()}
            </div>
          </Form.Field>
        </Form.Group>

        <Form.TextArea
          placeholder="Enter Reason..."
          value={fields.pmReason}
          onChange={(e, { value }) =>
            this.onInputChange({ name: 'pmReason', value })
          }
        />

        {isInternalUse && (
          <Form.TextArea
            placeholder="Enter instructions to trader..."
            value={fields.pmRemark}
            onChange={(e, { value }) =>
              this.onInputChange({ name: 'pmRemark', value })
            }
          />
        )}

        <Message
          error
          header="Fix Below Errors"
          visible={errorMsgs.length > 0}
          list={errorMsgs}
        />

        <Message
          warning
          header="Warnings"
          visible={warningMsgs.length > 0}
          list={warningMsgs}
        />
      </Form>
    );
  };

  _createSubTradesGrid = () => {
    const {
      gridWrapperStyle,
      hotTableSettings,
      subTrades,
      fields,
      fundBookByFund
    } = this.state;

    const hiddenColumnCodes = [
      ...(fields.algoCode !== 'INLINE' ? ['inlinePct'] : []),
      ...(fields.bookCode !== BOOK_W ? ['bookCode'] : [])
    ];
    const hiddenColumns = hiddenColumnCodes.map(columnCode =>
      _.findIndex(subTradeGridColumns, c => c.data === columnCode)
    );

    const cells = (row, col, field) => {
      if (field === 'bookCode' && !hiddenColumnCodes.includes(field)) {
        const st = subTrades[row] || {};
        const source = (fundBookByFund[st.fundCode] || [])
          .filter(
            fb =>
              fb.bookCode.includes(fields.bookCode) &&
              (fields.bookCode !== BOOK_W || isWBook(fb.bookCode))
          )
          .map(fb => fb.bookCode);
        return { source };
      }

      return {};
    };

    return (
      subTrades.length > 0 && (
        <div style={gridWrapperStyle}>
          <HotTable
            ref={this.hotTblRef}
            data={subTrades}
            beforeChange={this._beforeCellChange}
            beforeRemoveRow={this._beforeRemoveRow}
            // afterLoadData={this._afterLoadData}
            manualColumnResize={true}
            hiddenColumns={{
              columns: hiddenColumns,
              indicators: false
            }}
            {...hotTableSettings}
            cells={cells}
          ></HotTable>
        </div>
      )
    );
  };

  render() {
    const {
      info: { createNew },
      settings: { user = {} }
    } = this.props;

    const { isInitialized } = this.state;

    return (
      <Modal
        width={630}
        maskClosable={false}
        title={
          createNew
            ? `New Multi ${roleTradeLabel(user)}`
            : `Edit Multi ${roleTradeLabel(user)}`
        }
        visible={true}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        footer={[
          this._createSubmitBtn(this._debouncedSubmit),
          <Button key="close" type="primary" onClick={this.closeDialog}>
            Close
          </Button>
        ]}
      >
        <Spin tip="Initializing..." spinning={!isInitialized}>
          <div>{this._createForm()}</div>
          {this._createSubTradesGrid()}
        </Spin>
      </Modal>
    );
  }
}

export default MultiTradesDialog;
