import React, { Component } from 'react';
import ResearchReportHomePageHeader from './ResearchReportHomePageHeader';
// import ResearchReportTab from './ResearchReportTab';
// import MeetingRecordTab from './meetingRecord/MeetingRecordTab';
// import KeyDataTrackTab from './keyDataTrack/KeyDataTrackTab';
// import ReplayScoreTab from './replayScore/ReplayScoreTab';
import PushVotesTab from '../pushVotes/PushVotesTab';
// import ResearchReportDashBord from './dashbord/ResearchReportDashBord';
import CompanyStrategyTab from '../companyStrategy/CompanyStrategyTab';
import PushVoteReturnTab from '../pushVoteReturn/PushVoteReturnTab';
import { Tabs } from 'antd';

const TabPane = Tabs.TabPane;

class ResearchTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '6'
    };
  }

  tabChange = activeKey => {
    this.setState({
      activeTab: activeKey
    });
  };

  render() {
    return (
      <div style={{ height: '100%' }}>
        <ResearchReportHomePageHeader
          {...this.props}
          activeTab={this.state.activeTab}
        />
        <Tabs
          tabPosition={'top'}
          onChange={this.tabChange}
          activeKey={this.state.activeTab}
        >
          {/* <TabPane tab="DashBoard" key="5">
            <ResearchReportDashBord
              {...this.props}
              tabChange={this.tabChange}
            />
          </TabPane>
          <TabPane tab="Meeting Record" key="1">
            <MeetingRecordTab {...this.props} />
          </TabPane>
          <TabPane tab="Key Data Track" key="2">
            <KeyDataTrackTab {...this.props} />
          </TabPane>
          <TabPane tab="Replay Score" key="3">
            <ReplayScoreTab {...this.props} />
          </TabPane>
          <TabPane tab="Report" key="4">
            <ResearchReportTab {...this.props} />
          </TabPane> */}
          <TabPane tab="Stock Pitch" key="6">
            <PushVotesTab {...this.props} />
          </TabPane>
          <TabPane tab="Stock Pitch Review" key="8">
            <PushVoteReturnTab {...this.props} />
          </TabPane>
          <TabPane tab="Long/short Idea" key="7">
            <CompanyStrategyTab {...this.props} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}
export default ResearchTab;
