export const OMS_SNAPSHOT = 'OMS_SNAPSHOT';
export const OMS_UPDATE = 'OMS_UPDATE';
export const ORDERS_SELECT = 'ORDERS_SELECT';
export const ROUTES_SELECT = 'ROUTES_SELECT';

export const OMS_FUNDS_SELECT = 'OMS_FUNDS_SELECT';
export const OMS_FILTER_CHANGE = 'OMS_FILTER_CHANGE';

export const DIALOG_APPROVE_ORDERS = 'approveOrdersDialog';
export const DIALOG_ARCHIVE_ORDERS = 'archiveOrdersDialog';
export const DIALOG_ACTIVATE_ORDERS = 'activateOrdersDialog';
export const DIALOG_DELETE_ORDERS = 'deleteOrdersDialog';
export const DIALOG_RUN_COMPLIANCE = 'runComplianceDialog';
export const DIALOG_ACK_ROUTE_ERRORS = 'ackRouteErrorsDialog';
export const DIALOG_FINALIZE_ORDERS = 'finalizeOrdersDialog';
export const DIALOG_REJECT_ORDERS = 'rejectOrdersDialog';
export const DIALOG_ASSIGN_ORDERS = 'assignOrdersDialog';
export const DIALOG_EXECUTE_ORDERS = 'executeOrdersDialog';
export const DIALOG_VIEW_ORDER_CHANGES = 'viewOrderChangesDialog';
export const DIALOG_VIEW_HOLDINGS = 'viewHoldingsDialog';
export const DIALOG_VIEW_IPO_LIST = 'viewIPOListDialog';
export const DIALOG_EXECUTE_ROUTES = 'executeRoutesDialog';
export const DIALOG_EXECUTE_IPO_ROUTES = 'executeIpoRoutesDialog';
export const DIALOG_CANCEL_ROUTES = 'cancelRoutesDialog';
export const DIALOG_EXECUTE_LOCATES = 'executeLocatesDialog';
export const DIALOG_MARGIN_EXECUTE_LOCATES = 'executeMarginLocatesDialog';
export const DIALOG_CHECK_EXPENSIVE_BORROW = 'checkExpensiveBorrowDialog';
export const DIALOG_MANUAL_LOCATES = 'manualLocatesDialog';
export const DIALOG_VIEW_HELP = 'viewHelpDialog';
export const DIALOG_VIEW_RULES = 'viewRulesDialog';
export const DIALOG_OVERRIDE_CPL = 'overrideComplianceResultsDialog';
export const DIALOG_ENRICH_ORDERS = 'enrichOrdersDialog';
export const DIALOG_REPORT_SHOW = 'reportShowDialog';
export const DIALOG_CONTRACT_ROLLOVER = 'contractRolloverDialog';
export const DIALOG_TXN_LIMITS = 'txnLimitsDialog';
export const DIALOG_BROKERS_INFO = 'brokersInfoDialog';
export const DIALOG_SPLIT_ORDERS = 'splitOrdersDialog';
export const DIALOG_RECALL = 'recallDialog';

export const OMS_VIEW_MODE_LIVE = 'LIVE';
export const OMS_VIEW_MODE_HIST = 'HIST';

export const OMS_DATE_CHANGE = 'OMS_DATE_CHANGE';
export const OMS_VIEW_MODE_CHANGE = 'OMS_VIEW_MODE_CHANGE';
export const OMS_REQ_TYPE_CHANGE = 'OMS_REQ_TYPE_CHANGE';
export const OMS_VIEW_USESRV_MODE_CHANGE = 'OMS_VIEW_USESRV_MODE_CHANGE';

export const OMS_ORDER_BLOTTER_GET_REQUEST = 'OMS_ORDER_BLOTTER_GET_REQUEST';
export const OMS_ORDER_BLOTTER_GET_SUCCESS = 'OMS_ORDER_BLOTTER_GET_SUCCESS';
export const OMS_ORDER_BLOTTER_GET_FAIL = 'OMS_ORDER_BLOTTER_GET_FAIL';

export const DIALOG_EXECUTE_ROUTES_MODE = {
  NEW: 'New',
  UPDATE: 'Update',
  EXECUTE: 'Execute'
};

export const ROUTE_DYNAMIC_FIELDS = [
  'filled',
  'filledPct',
  'avgPrice',
  'status'
];
