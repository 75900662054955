import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { Loader } from 'semantic-ui-react';
import 'ag-grid-enterprise';
import _ from 'lodash';
import JsonTooltipComponent from '../../../common/ui/renderers/agGrid/JsonTooltipComponent';
import TagComponent from '../../../common/ui/renderers/agGrid/TagComponent';

class BrokerDialogMeetingListGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: props.analystMeetingReconGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true
      },
      frameworkComponents: {
        jsonTooltipComponent: JsonTooltipComponent,
        tagComponent: TagComponent
      },
      context: {
        gridComponent: this
      },
      autoGroupColumnDef: {
        headerName: 'Voting Month',
        field: 'votingMonth',
        cellClass: 'keyword'
      },
      groupDefaultExpanded: 1,
      rowGroupPanelShow: 'always',
      pivotPanelShow: 'always',
      sideBar: {
        toolPanels: ['columns', 'filters']
      },
      analystComplimentId: null,
      selectedData: null
    };
  }

  // componentDidMount() {
  //   this.loadAnalystList();
  // }

  // onReloadAnalystComplimentBtnClicked = () => {
  //   this.loadAnalystList();
  // };

  loadAnalystList = () => {
    // const { analystQueryParams } = this.props;
    const { votingMonth } = this.props.analystListModalData;
    const queryParams = {
      votingMonthFromStr: votingMonth.format('YYYY-MM'),
      votingMonthToStr: votingMonth.format('YYYY-MM')
    };
    // const pmName = this.props.user['englishName'];
    this.props.loadAnalystList(queryParams);
  };

  onNewAnalystComplimentBtnClicked = () => {
    const { votingMonth } = this.props.analystListModalData;
    this.props.getAnalystNameEditList({});
    this.props.getAnalystSectorList({});
    this.props.getAnalystDetailList({
      votingMonth: votingMonth.format('YYYY-MM')
    });
    this.props.changeAnalystModalInput({ name: 'isOpened', value: true });
  };

  onUpdateAnalystComplimentBtnClicked = () => {
    const data = Object.assign({}, this.state.selectedData);
    this.props.changeAnalystUpdateModalInput({ name: 'data', value: data });
    this.props.changeAnalystUpdateModalInput({ name: 'isOpened', value: true });
  };

  showRowItemUpdateDialog = () => {
    this.props.openAnalystComplimentUpdateDialog(
      this.state.analystComplimentId
    );
    this.setState({
      analystComplimentId: null
    });
  };

  deleteRowItem = () => {
    this.props.deleteAnalyst(
      { dataList: [{ id: this.state.analystComplimentId }] },
      this.props.analystQueryParams
    );
    this.setState({
      analystComplimentId: null,
      selectedData: null
    });
  };

  showDataRowItemUpdateDialog() {
    this.props.openAnalystComplimentUpdateDialog(
      this.state.analystComplimentId
    );
  }

  deleteDataRowItem() {
    this.props.deleteAnalystCompliment(this.state.analystComplimentId);
  }

  getRowNodeId = data => {
    return data.key;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  };

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    const selectedKeys = selectedRows.map(r => r.id);

    this.setState({
      analystComplimentId: selectedKeys.length ? selectedKeys[0] : null,
      selectedData: selectedRows[0]
    });
  };

  dateFromInputOnChange = date => {
    this.props.changeAnalystQueryParams({
      name: 'votingMonthFrom',
      value: date
    });
  };

  dateToInputOnChange = date => {
    this.props.changeAnalystQueryParams({ name: 'votingMonthTo', value: date });
  };

  render() {
    const { className = 'grid-wrapper', isLoaded, selectedData } = this.props;
    // const { votingMonth } = this.props.analystListModalData;

    //const { data } = this.props.analystListModalData;
    const allSellSideMeetingRecon = [];
    const data = [selectedData];

    data.forEach(item => {
      // 如果 sellSideMeetingRecon 存在并且是一个数组
      if (
        item.sellSideMeetingRecon &&
        Array.isArray(item.sellSideMeetingRecon)
      ) {
        // 将每个 sellSideMeetingRecon 添加到 allSellSideMeetingRecon 中
        allSellSideMeetingRecon.push(...item.sellSideMeetingRecon);
      }
    });
    const sortedRows = _.orderBy(
      allSellSideMeetingRecon,
      ['votingMonth'],
      ['desc']
    );

    return (
      <div className={className}>
        <div>
          <style>
            {`
            .filter {
              display: flex;
              justify-content: flex-end;
              padding: 1px;
              background-color: #1B1C1D;
              margin-bottom: 2px;
            }

            .filter .ui.selection.dropdown {
              border: 1px solid #54C8FF;
              margin-right: 5px;
              margin-top: 5px;
              margin-bottom: 5px;
            }

          `}
          </style>
        </div>
        <div className={`ag-theme-balham-dark ${className}`}>
          {!isLoaded ? (
            <Loader active inverted content="Loading..." />
          ) : (
            <AgGridReact
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              groupDefaultExpanded={this.state.groupDefaultExpanded}
              rowData={sortedRows}
              suppressAggFuncInHeader={true}
              animateRows={true}
              enableRangeSelection={true}
              rowGroupPanelShow={this.state.rowGroupPanelShow}
              pivotPanelShow={this.state.pivotPanelShow}
              frameworkComponents={this.state.frameworkComponents}
              rowSelection="single"
              context={this.state.context}
              sideBar={this.state.sideBar}
              onGridReady={this.onGridReady}
              onSelectionChanged={this.onSelectionChanged}
            />
          )}
        </div>
      </div>
    );
  }
}

export default BrokerDialogMeetingListGrid;
