import spected from 'spected';
import Route from '../../entities/Route';
import _ from 'lodash';
import TickerUtils from 'common/utils/TickerUtils';

export const HARD_RULE = 'HARD';
export const SOFT_RULE = 'SOFT';
const QUANT_BOOKS = ['T12', 'T34'];
const ISOLATED_ACCT_BOOKS = ['T83'];
const ON_SHORE_FUNDS = ['CVF', 'SLHL', 'ZJNF', 'DCL', 'PLATCNY'];

const _createSpec = (values, ctx) => {
  const {
    ruleType,
    brokerMap = {},
    custodianMap = {},
    venueSvcInfos = [],
    user = {},
    holdings = [],
    routes = [],
    extraHoldings = []
  } = ctx;

  // predicates
  const notEmpty = value => !!value;
  const positiveNumber = value => parseFloat(value) > 0;
  // const validInstruction = value =>
  //   values['venue'] !== 'CATS' ||
  //   ['INLINE', 'VWAP', 'TWAP']
  //     .map(v => value && value.toUpperCase().includes(v))
  //     .find(Boolean);

  // error messages
  const notEmptyMsg = field => `${field} should not be empty.`;
  const positiveNumberMsg = field => `${field} should be greated than 0.`;

  // rules
  // const instructionRule = [
  //   [notEmpty, notEmptyMsg('instruction')],
  //   [
  //     validInstruction,
  //     'Instruction must includes INLINE, VWAP or TWAP keyword for CATS venue.'
  //   ]
  // ];
  const algoCodeRule = [[notEmpty, notEmptyMsg('Algo Code')]];
  const brokerRule = [[notEmpty, notEmptyMsg('Broker')]];
  const custodianRule = [[notEmpty, notEmptyMsg('Custodian')]];

  const checkCloseTradeQty = v => {
    if (Route.isOpen(values)) return true;

    const holdingKey = Route.parseHoldingKey(values);
    const matchedHolding = holdings.find(h => h.key === holdingKey);
    if (!matchedHolding) return false;

    const aggRouteQty = (_.isEmpty(routes)
      ? [values]
      : routes.filter(r => Route.parseHoldingKey(r) === holdingKey)
    ).reduce((prev, r) => {
      return (
        prev + Route.parseSign(r) * (Route.isCancelled(r) ? r.filledQty : r.qty)
      );
    }, 0);

    return (
      Math.abs(matchedHolding.theoreticalQtyForClose) >= Math.abs(aggRouteQty)
    );
  };

  const checkCloseStartQty = v => {
    if (Route.isOpen(values)) return true;
    if (Route.isCloseTdy(values)) return true;

    const { symbol = '' } = values.order || {};
    if (_.isNil(symbol) || !symbol.endsWith('.SHF')) return true;

    const holdingKey = Route.parseHoldingKey(values);
    const matchedHolding = holdings.find(h => h.key === holdingKey);
    if (!matchedHolding) return false;

    const aggCloseRouteQty = (_.isEmpty(routes)
      ? [values]
      : routes.filter(
          r =>
            Route.parseHoldingKey(r) === holdingKey &&
            ['SELL', 'COVR'].includes(r.side)
        )
    ).reduce((prev, r) => {
      return (
        prev + Route.parseSign(r) * (Route.isCancelled(r) ? r.filledQty : r.qty)
      );
    }, 0);

    return (
      Math.abs(matchedHolding.theoQtyStartLeft) >= Math.abs(aggCloseRouteQty)
    );
  };

  const _inIsolatedAcct = rh => {
    const { fundCode, bookCode, ticker, custodian } = rh;
    if (bookCode.startsWith('FL')) return true;

    if (QUANT_BOOKS.includes(bookCode)) {
      if (ON_SHORE_FUNDS.includes(fundCode)) {
        const exch = TickerUtils.parseExch(ticker);
        return exch == null || ['CH', 'C1', 'C2'].includes(exch);
      }
      return custodian === 'CITIC';
    }

    return ISOLATED_ACCT_BOOKS.includes(bookCode);
  };

  const parseCfdType = r => {
    const cfdType = ['CFD', 'FFS'].includes(r.cfdType) ? 'SWAP' : r.cfdType;
    return cfdType;
  };

  const noBoxedAgainstCustodianHolding = v => {
    // Only check new open route.
    if (values.refId) return true;
    if (!values.custodian) return true;
    if (!Route.isOpen(values)) return true;
    if (_inIsolatedAcct(values)) return true;

    // // If find matched holding, then ignore checking.
    // const holdingKey = Route.parseHoldingKey(values);
    // const matchedHolding = holdings.find(h => h.key === holdingKey);
    // if (matchedHolding) return true;
    const matchedOppositeHolding = holdings.find(
      h =>
        !_inIsolatedAcct(h) &&
        h.fundCode === values.fundCode &&
        h.custodianCode === values.custodian &&
        h.ticker === values.ticker &&
        h.accountType === values.accountType &&
        parseCfdType(h) === parseCfdType(values) &&
        Math.abs(h.theoreticalQty) > 0 &&
        h.direction !== Route.parseDirection(values)
    );
    return !matchedOppositeHolding;
  };

  // const checkPbCloseQty = v => {
  //   // Only check new open route.
  //   if (values.refId) return true;
  //   if (!values.custodian) return true;
  //   if (Route.isOpen(values)) return true;
  //   if (_inIsolatedAcct(values)) return true;

  //   // // If find matched holding, then ignore checking.
  //   // const holdingKey = Route.parseHoldingKey(values);
  //   // const matchedHolding = holdings.find(h => h.key === holdingKey);
  //   // if (matchedHolding) return true;
  //   const netQty = _.sum(
  //     holdings
  //       .filter(
  //         h =>
  //           !_inIsolatedAcct(h) &&
  //           h.fundCode === values.fundCode &&
  //           h.custodianCode === values.custodian &&
  //           h.ticker === values.ticker &&
  //           h.accountType === values.accountType &&
  //           parseCfdType(h) === parseCfdType(values)
  //       )
  //       .map(r => r.theoreticalQtyForClose)
  //   );
  //   const sign = ['COVR', 'COVR_T'].includes(values.side) ? -1 : 1;
  //   return values.qty <= netQty * sign;
  // };

  const longTradePTHHoldingCheck = v => {
    // Only check new open route.
    if (values.refId) return true;
    if (_.isEmpty(extraHoldings)) return true;
    if (!values.custodian) return true;
    if (Route.parseDirection(values) !== 'LONG') return true;
    if (!Route.isOpen(values)) return true;

    const matchedOppositeHolding = extraHoldings.find(
      h =>
        h.positionTypeCode === 'PTH' &&
        h.direction === 'SHORT' &&
        h.fundCode === values.fundCode &&
        h.custodianCode === values.custodian &&
        h.ticker === values.ticker &&
        h.accountType === values.accountType &&
        parseCfdType(h) === parseCfdType(values)
    );
    return !matchedOppositeHolding;
  };

  const sameBrokerShrtPTHHoldingCheck = v => {
    // Only check new open route.
    if (values.refId) return true;
    if (_.isEmpty(extraHoldings)) return true;
    if (!values.custodian) return true;
    if (!values.broker) return true;
    if (Route.parseDirection(values) !== 'SHORT') return true;
    if (!Route.isOpen(values)) return true;

    const matchedHolding = extraHoldings.find(
      h =>
        h.positionTypeCode === 'PTH' &&
        h.fundCode === values.fundCode &&
        h.custodianCode === values.custodian &&
        h.bookCode === values.bookCode &&
        h.ticker === values.ticker &&
        h.accountType === values.accountType &&
        parseCfdType(h) === parseCfdType(values)
    );

    if (!_.isEmpty(matchedHolding)) return true;

    const matchedOppositeHolding = extraHoldings.find(
      h =>
        h.positionTypeCode === 'PTH' &&
        h.fundCode === values.fundCode &&
        h.custodianCode === values.custodian &&
        h.bookCode !== values.bookCode &&
        h.ticker === values.ticker &&
        h.accountType === values.accountType &&
        parseCfdType(h) === parseCfdType(values)
    );
    return !matchedOppositeHolding;
  };

  const noCashShrt = v => {
    // Only check new SHRT route.
    if (values.refId) return true;
    if (values.side !== 'SHRT') return true;
    if (['CFD', 'FFS'].includes(values.cfdType)) return true;

    const { isNoCashShrt = 0 } = values.order || {};
    return !isNoCashShrt;
  };

  // const invalidSideAgainstCustodianHolding = v => {
  //   // Only check new sell route.
  //   if (values.refId) return true;
  //   if (values.side !== 'SELL') return true;

  //   const aggCustodianHoldingQty = holdings
  //     .filter(h => h.custodianCode === values.custodian)
  //     .reduce((acc, c) => acc + c.theoreticalQty, 0);

  //   if (aggCustodianHoldingQty <= 0) return false;
  //   if (aggCustodianHoldingQty < values.qty) return false;
  //   return true;
  // };

  // const qtyRule = [
  //   [notEmpty, notEmptyMsg('Qty')],
  //   [positiveNumber, positiveNumberMsg('Qty')]
  // ];

  const softQtyRule = [
    [checkCloseTradeQty, 'No enough holding qty could be closed.'],
    [
      checkCloseStartQty,
      'No enough holding start qty could be closed, might need to use SELL_T or COVR_T.'
    ],
    [positiveNumber, positiveNumberMsg('Qty')]
    // [checkPbCloseQty, 'No enough pb holding qty could be closed.']
  ];

  const sideRule = [
    [noCashShrt, 'The cash short for this ticker is not allowed.'],
    [
      noBoxedAgainstCustodianHolding,
      'Might cause boxed holding under specified custodian.'
    ]
    // [
    //   invalidSideAgainstCustodianHolding,
    //   'The side might be SHRT since not enough LONG custodian holding could be closed.'
    // ]
  ];

  const orderComplianceMsg = () => {
    const { complianceStatus = '', complianceError = '' } = values.order || {};
    switch (complianceStatus) {
      case 'PENDING':
        return 'Order compliance check is still undergoing.';
      case 'FAIL':
        return `Order compliance check is failed due to errors: ${complianceError}`;
      case 'WARN':
        return `Order compliance check is failed due to warnings: ${complianceError}`;
      case 'SKIP':
        return 'Order compliance is unchecked, please check manually.';
      default:
        return null;
    }
  };

  const orderComplianceRule = [
    [
      value => {
        if (!value) return true;
        return value.complianceStatus === 'PASS';
      },
      orderComplianceMsg()
    ]
  ];

  const invalidTrader = o => {
    return o.traderCode === user.englishName;
  };

  const orderRule = [
    [
      invalidTrader,
      'You are not the trader of the order, pls assign to you first.'
    ]
  ];

  const sameBrokerAndCustodian = value => {
    if (!['CFD', 'FFS'].includes(value)) return true;
    const selectedBroker = brokerMap[values.broker];
    if (!selectedBroker) return true;
    if (selectedBroker.custodianCode === values.custodian) return true;
    return false;
  };
  const cfdTypeRule = [
    [
      sameBrokerAndCustodian,
      'The broker and custodian must be same for swap trade.'
    ]
  ];

  const validCustodian = value => {
    const { fundCode } = values;
    const selectedCustodian = custodianMap[value];
    if (!selectedCustodian) return false;

    return (selectedCustodian.txnFunds || []).includes(fundCode);
  };
  const softCustodianRule = [
    [
      validCustodian,
      `The custodian might not be valid for fund ${values.fundCode}.`
    ],
    [longTradePTHHoldingCheck, `The custodian has PTH SHORT positions.`],
    [
      sameBrokerShrtPTHHoldingCheck,
      `Might use the PTH positions of other teams.`
    ]
  ];

  const validLimitPrice = value => {
    const { orderType } = values;

    if (orderType === 'LMT' && (value === '' || value === null)) return false;
    return true;
  };

  const limitPriceRule = [
    [validLimitPrice, `Limit px must not be null for limit order`]
  ];

  const validVenueService = value => {
    const venueSvcInfo = venueSvcInfos.find(v => v.name === value);
    if (!venueSvcInfo) return true;

    const isVenueServiceStopped =
      venueSvcInfo && venueSvcInfo.status !== 'STARTED';
    return !isVenueServiceStopped;
  };

  const validVenuePermission = value => {
    const venueSvcInfo = venueSvcInfos.find(v => v.name === value);
    if (!venueSvcInfo) return true;

    const hasNoPermission =
      venueSvcInfo &&
      venueSvcInfo.needAuth &&
      !(venueSvcInfo.authedUsers || []).includes(user.englishName);
    return !hasNoPermission;
  };

  const manualCiticVenue = value => {
    if (value === 'MANUAL') return true;
    if (!Route.isShort(values)) return true;
    if (!['CLET'].includes(values.broker)) return true;

    const exch = TickerUtils.parseExch(values.ticker);
    if (!exch) return true;
    if (!['CH', 'C1', 'C2'].includes(exch)) return true;
    return false;
  };

  const venueRule = [
    [
      validVenueService,
      `${values.venue} service is not available, please contact IT admin for help`
    ],
    [
      validVenuePermission,
      `You have no permission to send route to ${values.venue}`
    ],
    [
      manualCiticVenue,
      `The venue should be MANUAL for short CH equities through ${values.broker}`
    ]
  ];

  const validIsDirectline = value => {
    return value;
  };
  const softIsDirectlineRule = [
    [validIsDirectline, `The route to the specified broker is not directline.`]
  ];

  const rules = {
    [HARD_RULE]: {
      // instruction: instructionRule,
      algoCode: algoCodeRule,
      broker: brokerRule,
      custodian: custodianRule,
      // qty: qtyRule,
      order: orderRule,
      limitPrice: limitPriceRule
    },
    [SOFT_RULE]: {
      side: sideRule,
      order: orderComplianceRule,
      cfdType: cfdTypeRule,
      venue: venueRule,
      qty: softQtyRule,
      custodian: softCustodianRule,
      isDirectLine: softIsDirectlineRule
    }
  };

  return rules[ruleType];
};

const _extractErrors = validationResult => {
  const FIRST_ERROR = 0;
  return Object.keys(validationResult).reduce((errors, field) => {
    return validationResult[field] !== true
      ? { ...errors, [field]: validationResult[field][FIRST_ERROR] }
      : errors;
  }, {});
};

const RouteValidator = {
  validate: (values, ctx) => {
    const spec = _createSpec(values, ctx);
    const validationResult = spected(spec, values);
    return _extractErrors(validationResult);
  }
};

export default RouteValidator;
