import React, { Component } from 'react';
import { Loader } from 'semantic-ui-react';
import SplitPane from 'react-split-pane';
import OrderGrid from './order/OrderGrid';
import RouteGrid from './route/RouteGrid';
import AllocationGrid from './allocation/AllocationGrid';
import ApproveOrdersDialog from './dialogs/ApproveOrdersDialog';
import FinalizeOrdersDialog from './dialogs/FinalizeOrdersDialog';
import ArchiveOrdersDialog from './dialogs/ArchiveOrdersDialog';
import ActivateOrdersDialog from './dialogs/ActivateOrdersDialog';
import RejectOrdersDialog from './dialogs/RejectOrdersDialog';
import AssignOrdersDialog from './dialogs/AssignOrdersDialog';
import ViewOrderChangesDialog from './dialogs/ViewOrderChangesDialog';
import ViewHoldingsDialog from './dialogs/ViewHoldingsDialog';
import ViewIPOListDialog from './dialogs/ViewIPOListDialog';
import ExecuteRoutesDialog from './dialogs/ExecuteRoutesDialog';
import RunComplianceDialog from './dialogs/RunComplianceDialog';
import OverrideCPLDialog from './dialogs//OverrideCPLDialog';
import AckRouteErrorsDialog from './dialogs/AckRouteErrorsDialog';
import CancelRoutesDialog from './dialogs/CancelRoutesDialog';
import ExecuteLocatesDialog from './dialogs/ExecuteLocatesDialog';
import ExecuteIPORoutesDialog from './dialogs/ExecuteIPORoutesDialog';
import ReportShowDialog from './dialogs/ReportShowDialog';
import TxnLimitsDialog from './dialogs/TxnLimitsDialog';
import BrokersRuleDialog from './dialogs/BrokersRuleDialog';
import ContractApiLocateDialog from './dialogs/ContractApiLocateDialog';
import RecallDialogs from './dialogs/RecallDialogs';
import QuantTradesDialog from 'features/order/components/dialogs/QuantTradesDialog';

import {
  DIALOG_APPROVE_ORDERS,
  DIALOG_ARCHIVE_ORDERS,
  DIALOG_FINALIZE_ORDERS,
  DIALOG_REJECT_ORDERS,
  DIALOG_ASSIGN_ORDERS,
  DIALOG_VIEW_ORDER_CHANGES,
  DIALOG_VIEW_HOLDINGS,
  DIALOG_EXECUTE_ROUTES,
  DIALOG_EXECUTE_IPO_ROUTES,
  DIALOG_VIEW_HELP,
  DIALOG_ACTIVATE_ORDERS,
  DIALOG_DELETE_ORDERS,
  DIALOG_RUN_COMPLIANCE,
  DIALOG_ACK_ROUTE_ERRORS,
  DIALOG_CANCEL_ROUTES,
  DIALOG_EXECUTE_LOCATES,
  DIALOG_VIEW_RULES,
  DIALOG_OVERRIDE_CPL,
  DIALOG_VIEW_IPO_LIST,
  DIALOG_REPORT_SHOW,
  DIALOG_ENRICH_ORDERS,
  DIALOG_MANUAL_LOCATES,
  DIALOG_CONTRACT_ROLLOVER,
  DIALOG_TXN_LIMITS,
  DIALOG_BROKERS_INFO,
  DIALOG_CHECK_EXPENSIVE_BORROW,
  DIALOG_SPLIT_ORDERS,
  DIALOG_MARGIN_EXECUTE_LOCATES,
  DIALOG_RECALL
} from '../omsConstants';
import { DIALOG_EXECUTE_QUANT_TRADES } from 'features/order/orderConstants';
import FilterBar from './FilterBar';
import { HotKeys } from 'react-hotkeys';
import './OrderBlotter.css';
import './dialogs/OmsDialog.css';
import ViewHelpDialog from './dialogs/ViewHelpDialog';
import { DraggableModalProvider } from 'ant-design-draggable-modal';
import DeleteOrdersDialog from './dialogs/DeleteOrdersDialog';
import ViewRulesDialog from './dialogs/ViewRulesDialog';
import EnrichOrdersDialog from './dialogs/EnrichOrdersDialog';
import ManualLocatesDialog from './dialogs/ManualLocatesDialog';
import ContractRolloverDialog from './dialogs/ContractRolloverDialog';
import CheckExpensiveBorrowDialog from './dialogs/CheckExpensiveBorrowDialog';
import SplitOrdersDialog from './dialogs/SplitOrdersDialog';

const hotKeyMap = {
  viewHoldings: 'ctrl+q'
};

class OrderBlotter extends Component {
  constructor(props) {
    super(props);

    this.orderGridRef = React.createRef();
  }

  createDialog = (dialogCode, info) => {
    const {
      holdingMap,
      selectedOrders,
      selectedRoutes,
      routes,
      settings,
      ui,
      liveRiskInfos
    } = this.props;

    switch (dialogCode) {
      case DIALOG_APPROVE_ORDERS:
        return (
          <ApproveOrdersDialog
            key={dialogCode}
            closeDialog={this._onCloseDialog}
            selectedOrders={selectedOrders}
            submitDialogSuccess={this._onSubmitDialogSuccess}
            settings={settings}
          />
        );
      case DIALOG_ARCHIVE_ORDERS:
        return (
          <ArchiveOrdersDialog
            key={dialogCode}
            closeDialog={this._onCloseDialog}
            selectedOrders={selectedOrders}
            submitDialogSuccess={this._onSubmitDialogSuccess}
            settings={settings}
          />
        );
      case DIALOG_ACTIVATE_ORDERS:
        return (
          <ActivateOrdersDialog
            key={dialogCode}
            closeDialog={this._onCloseDialog}
            selectedOrders={selectedOrders}
            submitDialogSuccess={this._onSubmitDialogSuccess}
            settings={settings}
          />
        );
      case DIALOG_DELETE_ORDERS:
        return (
          <DeleteOrdersDialog
            key={dialogCode}
            closeDialog={this._onCloseDialog}
            selectedOrders={selectedOrders}
            submitDialogSuccess={this._onSubmitDialogSuccess}
            settings={settings}
          />
        );
      // case DIALOG_EXECUTE_ORDERS:
      //   return (
      //     <ExecuteOrdersDialog
      //       key={dialogCode}
      //       closeDialog={this._onCloseDialog}
      //       routes={routes}
      //     />
      //   );
      case DIALOG_FINALIZE_ORDERS:
        return (
          <FinalizeOrdersDialog
            key={dialogCode}
            closeDialog={this._onCloseDialog}
            selectedOrders={selectedOrders}
            submitDialogSuccess={this._onSubmitDialogSuccess}
            settings={settings}
          />
        );
      case DIALOG_REJECT_ORDERS:
        return (
          <RejectOrdersDialog
            key={dialogCode}
            closeDialog={this._onCloseDialog}
            selectedOrders={selectedOrders}
            submitDialogSuccess={this._onSubmitDialogSuccess}
            settings={settings}
          />
        );
      case DIALOG_ASSIGN_ORDERS:
        return (
          <AssignOrdersDialog
            key={dialogCode}
            closeDialog={this._onCloseDialog}
            selectedOrders={selectedOrders}
            submitDialogSuccess={this._onSubmitDialogSuccess}
            settings={settings}
          />
        );
      case DIALOG_VIEW_ORDER_CHANGES:
        return (
          <ViewOrderChangesDialog
            key={dialogCode}
            selectedOrders={selectedOrders}
            closeDialog={this._onCloseDialog}
          />
        );
      case DIALOG_RUN_COMPLIANCE:
        return (
          <RunComplianceDialog
            key={dialogCode}
            selectedOrders={selectedOrders}
            closeDialog={this._onCloseDialog}
            submitDialogSuccess={this._onSubmitDialogSuccess}
          />
        );
      case DIALOG_OVERRIDE_CPL:
        return (
          <OverrideCPLDialog
            key={dialogCode}
            selectedOrders={selectedOrders}
            closeDialog={this._onCloseDialog}
            submitDialogSuccess={this._onSubmitDialogSuccess}
            settings={settings}
          />
        );
      case DIALOG_ENRICH_ORDERS:
        return (
          <EnrichOrdersDialog
            key={dialogCode}
            selectedOrders={selectedOrders}
            closeDialog={this._onCloseDialog}
            submitDialogSuccess={this._onSubmitDialogSuccess}
            settings={settings}
          />
        );
      case DIALOG_ACK_ROUTE_ERRORS:
        return (
          <AckRouteErrorsDialog
            key={dialogCode}
            selectedOrders={selectedOrders}
            closeDialog={this._onCloseDialog}
            submitDialogSuccess={this._onSubmitDialogSuccess}
          />
        );
      case DIALOG_VIEW_HOLDINGS:
        return (
          <ViewHoldingsDialog
            key={dialogCode}
            holdingMap={holdingMap}
            closeDialog={this._onCloseDialog}
          />
        );
      case DIALOG_REPORT_SHOW:
        return (
          <ReportShowDialog
            key={dialogCode}
            closeDialog={this._onCloseDialog}
          />
        );
      case DIALOG_VIEW_IPO_LIST:
        return (
          <ViewIPOListDialog
            key={dialogCode}
            closeDialog={this._onCloseDialog}
          />
        );
      case DIALOG_EXECUTE_ROUTES:
        return (
          <ExecuteRoutesDialog
            key={dialogCode}
            info={info}
            selectedOrders={selectedOrders}
            routes={routes}
            selectedRoutes={selectedRoutes}
            closeDialog={this._onCloseDialog}
            submitDialogSuccess={this._onSubmitDialogSuccess}
            settings={settings}
            ui={ui}
          />
        );
      case DIALOG_EXECUTE_IPO_ROUTES:
        return (
          <ExecuteIPORoutesDialog
            key={dialogCode}
            info={info}
            selectedOrders={selectedOrders}
            routes={routes}
            selectedRoutes={selectedRoutes}
            closeDialog={this._onCloseDialog}
            submitDialogSuccess={this._onSubmitDialogSuccess}
            settings={settings}
            ui={ui}
          />
        );
      case DIALOG_CANCEL_ROUTES:
        return (
          <CancelRoutesDialog
            key={dialogCode}
            settings={settings}
            selectedOrders={selectedOrders}
            selectedRoutes={selectedRoutes}
            closeDialog={this._onCloseDialog}
            submitDialogSuccess={this._onSubmitDialogSuccess}
          />
        );
      case DIALOG_EXECUTE_LOCATES:
        return (
          <ExecuteLocatesDialog
            key={dialogCode}
            selectedOrders={selectedOrders}
            closeDialog={this._onCloseDialog}
            submitDialogSuccess={this._onSubmitDialogSuccess}
            ui={ui}
          />
        );
      case DIALOG_CHECK_EXPENSIVE_BORROW:
        return (
          <CheckExpensiveBorrowDialog
            key={dialogCode}
            selectedOrders={selectedOrders}
            closeDialog={this._onCloseDialog}
            submitDialogSuccess={this._onSubmitDialogSuccess}
          />
        );
      case DIALOG_MANUAL_LOCATES:
        return (
          <ManualLocatesDialog
            key={dialogCode}
            selectedOrders={selectedOrders}
            closeDialog={this._onCloseDialog}
            submitDialogSuccess={this._onSubmitDialogSuccess}
            ui={ui}
          />
        );
      case DIALOG_MARGIN_EXECUTE_LOCATES:
        return (
          <ContractApiLocateDialog
            key={dialogCode}
            selectedOrders={selectedOrders}
            closeDialog={this._onCloseDialog}
            submitDialogSuccess={this._onSubmitDialogSuccess}
            ui={ui}
          />
        );
      case DIALOG_VIEW_RULES:
        return (
          <ViewRulesDialog
            key={dialogCode}
            closeDialog={this._onCloseDialog}
            settings={settings}
          />
        );
      case DIALOG_CONTRACT_ROLLOVER:
        return (
          <ContractRolloverDialog
            key={dialogCode}
            closeDialog={this._onCloseDialog}
            settings={settings}
          />
        );
      case DIALOG_TXN_LIMITS:
        return (
          <TxnLimitsDialog key={dialogCode} closeDialog={this._onCloseDialog} />
        );
      case DIALOG_BROKERS_INFO:
        return (
          <BrokersRuleDialog
            key={dialogCode}
            closeDialog={this._onCloseDialog}
          />
        );
      case DIALOG_SPLIT_ORDERS:
        return (
          <SplitOrdersDialog
            key={dialogCode}
            selectedOrders={selectedOrders}
            closeDialog={this._onCloseDialog}
            submitDialogSuccess={this._onSubmitDialogSuccess}
          />
        );
      case DIALOG_RECALL:
        return (
          <RecallDialogs
            key={dialogCode}
            info={info}
            closeDialog={this._onCloseDialog}
            submitDialogSuccess={this._onSubmitDialogSuccess}
            openDialog={this.props.openDialog}
            settings={settings}
            ui={ui}
          />
        );
      case DIALOG_EXECUTE_QUANT_TRADES:
        return (
          <QuantTradesDialog
            key={dialogCode}
            info={info}
            closeDialog={this.props.closeDialog}
            submitDialogSuccess={this.props.submitDialogSuccess}
            settings={settings}
            liveRiskInfos={liveRiskInfos}
          />
        );
      case DIALOG_VIEW_HELP:
        return (
          <ViewHelpDialog key={dialogCode} closeDialog={this._onCloseDialog} />
        );
      default:
        return null;
    }
  };

  createDialogs = () => {
    const { dialogs } = this.props;
    return Object.keys(dialogs).map(dialogCode => {
      const dialog = dialogs[dialogCode];
      return dialog.isOpened
        ? this.createDialog(dialogCode, dialog.info)
        : null;
    });
  };

  _onSubmitDialogSuccess = (dialogCode, info) => {
    const { submitDialogSuccess } = this.props;

    // Clear row selection of order grid.
    this.orderGridRef.current.clearSelection();

    submitDialogSuccess(dialogCode, info);
  };

  _onCloseDialog = dialogCode => {
    const { closeDialog } = this.props;

    // Clear row selection of order grid.
    this.orderGridRef.current.clearSelection();

    closeDialog(dialogCode);
  };

  _createHotKeyHandlers = () => {
    const { openDialog } = this.props;
    return {
      viewHoldings: _ => openDialog(DIALOG_VIEW_HOLDINGS)
    };
  };

  componentDidMount() {
    const {
      settings: { user }
    } = this.props;
    if (!user) return;

    this.props.sendSubscription({
      view: 'OMS',
      userName: user.englishName
    });
  }

  componentDidUpdate(prevProps) {
    const {
      settings: { user: prevUser }
    } = prevProps;
    const {
      settings: { user }
    } = this.props;

    if (!prevUser && user) {
      this.props.sendSubscription({
        view: 'OMS',
        userName: user.englishName
      });
    }
  }

  render() {
    const { isLoaded } = this.props;

    if (!isLoaded) return <Loader inverted active content="Loading..." />;

    const dialogs = this.createDialogs();
    const hotKeyHandlers = this._createHotKeyHandlers();

    return (
      <HotKeys
        keyMap={hotKeyMap}
        handlers={hotKeyHandlers}
        style={{ width: '100%', height: '100%' }}
      >
        <div className="orderBlotterWrapper">
          <SplitPane
            split="vertical"
            defaultSize="5%"
            style={{ position: 'relative' }}
          >
            <FilterBar {...this.props} />
            <SplitPane split="horizontal" defaultSize="70%">
              <div style={{ overflow: 'hidden', flexGrow: '1' }}>
                <OrderGrid {...this.props} ref={this.orderGridRef} />
              </div>
              <SplitPane split="vertical" defaultSize="50%">
                <RouteGrid {...this.props} />
                <AllocationGrid {...this.props} />
              </SplitPane>
            </SplitPane>
          </SplitPane>

          <DraggableModalProvider>{dialogs}</DraggableModalProvider>
        </div>
      </HotKeys>
    );
  }
}

export default OrderBlotter;
