import config from '../../../common/config';
import apiClient from '../../../common/api/apiClient';

const approveOrders = cmds => {
  const url = config.api.REST_URL + '/trade/approveTrades';
  return apiClient.post(url, cmds);
};

const rejectOrders = cmds => {
  const url = config.api.REST_URL + '/trade/rejectTrades';
  return apiClient.post(url, cmds);
};

const assignOrders = cmds => {
  const url = config.api.REST_URL + '/trade/assignTrades';
  return apiClient.post(url, cmds);
};

const archiveOrders = cmds => {
  const url = config.api.REST_URL + '/trade/archiveTrades';
  return apiClient.post(url, cmds);
};

const activateOrders = cmds => {
  const url = config.api.REST_URL + '/trade/activateTrades';
  return apiClient.post(url, cmds);
};

const deleteOrders = cmds => {
  const url = config.api.REST_URL + '/trade/deleteTrades';
  return apiClient.post(url, cmds);
};

const runCompliance = cmds => {
  const url = config.api.REST_URL + '/trade/runCompliance';
  return apiClient.post(url, cmds);
};

const ackOrderRouteErrors = cmds => {
  const url = config.api.REST_URL + '/trade/ackOrderRouteErrors';
  return apiClient.post(url, cmds);
};

const finalizeOrders = cmds => {
  const url = config.api.REST_URL + '/trade/finalizeTrades';
  return apiClient.post(url, cmds);
};

const getOrderBlotter = ({ startDate, endDate }) => {
  const qs = new URLSearchParams({ startDate, endDate });
  const url = `${config.api.REST_URL}/trade/getOrderBlotter?${qs}`;

  return apiClient.get(url);
};

const getAuditChanges = ({ tableName = 'TradeRequest', pks }) => {
  const url = `${config.api.REST_URL}/trade/getAuditChanges`;
  const input = { tableName, pks };

  return apiClient.post(url, input);
};

const getTraders = () => {
  const qs = new URLSearchParams({ role: 'Trader' });
  const url = `${config.api.REST_URL}/auth/getUsers?${qs}`;

  return apiClient.get(url);
};

const check = (orders, ruleids) => {
  const url = config.api.REST_URL + '/trade/check';
  return apiClient.post(url, { orders, ruleids });
};

const overrideCplResults = cplResults => {
  const url = `${config.api.REST_URL}/trade/overrideCplResults`;
  return apiClient.post(url, cplResults);
};

const enrichOrders = cmds => {
  const url = `${config.api.REST_URL}/trade/enrichTrades`;
  return apiClient.post(url, cmds);
};

const getExecutionOptions = () => {
  const url = `${config.api.REST_URL}/trade/getExecutionOptions`;
  return apiClient.get(url);
};

const calcExecutionContexts = inputs => {
  const url = `${config.api.REST_URL}/trade/calcExecutionContexts`;
  return apiClient.post(url, inputs);
};

const calcExecutionInfos = inputs => {
  const url = config.api.REST_URL + '/trade/calcExecutionInfos';
  return apiClient.post(url, inputs);
};

const getVenueServiceInfos = () => {
  const url = config.api.REST_URL + '/trade/getVenueServiceInfos';
  return apiClient.get(url);
};

// const getHoldings = ticker => {
//   const qs = new URLSearchParams({ ticker });
//   const url = `${config.api.REST_URL}/portfolio/getHoldings?${qs}`;
//   return apiClient.get(url);
// };

const submitRoutes = routes => {
  const url = config.api.REST_URL + '/trade/submitRoutes';
  return apiClient.post(url, { routes });
};

const sendRoutes = cmds => {
  const url = config.api.REST_URL + '/trade/sendRoutes';
  return apiClient.post(url, cmds);
};

const cancelRoutes = cmds => {
  const url = config.api.REST_URL + '/trade/cancelRoutes';
  return apiClient.post(url, cmds);
};

const addOrders = orders => {
  const url = `${config.api.REST_URL}/trade/addTrades`;
  return apiClient.post(url, orders);
};

const updateOrders = orders => {
  const url = `${config.api.REST_URL}/trade/updateTrades`;
  return apiClient.post(url, orders);
};

const addLocates = locates => {
  const url = `${config.api.REST_URL}/trade/addLocates`;
  return apiClient.post(url, { locates });
};

const getLocateOptions = () => {
  const url = `${config.api.REST_URL}/trade/getLocateOptions`;
  return apiClient.get(url);
};

const calcLocateContexts = inputs => {
  const url = `${config.api.REST_URL}/trade/calcLocateContexts`;
  return apiClient.post(url, inputs);
};

const calcCashLocateContexts = inputs => {
  const url = `${config.api.REST_URL}/trade/calcCashLocateContexts`;
  return apiClient.post(url, inputs);
};

const getRules = () => {
  const url = `${config.api.REST_URL}/trade/getRules`;
  return apiClient.get(url);
};

const submitRules = rules => {
  const url = `${config.api.REST_URL}/trade/submitRules`;
  return apiClient.post(url, { rules });
};

const getRuleOptions = () => {
  const url = `${config.api.REST_URL}/trade/getRuleOptions`;
  return apiClient.get(url);
};

const getHelp = () => {
  const url = `${config.api.REST_URL}/trade/getHelp`;
  return apiClient.get(url);
};

const getShortListByTickers = params => {
  const url = `${config.api.RESEARCH_URL}/shortable/getShortListByTickers`;
  return apiClient.post(url, params);
};

const getIPOList = params => {
  const url = `${config.api.RESEARCH_URL}/ipoInfo/getIPOList`;
  return apiClient.post(url, params);
};

const updateIPOList = params => {
  const url = `${config.api.RESEARCH_URL}/ipoInfo/updateIPOList`;
  return apiClient.post(url, params);
};

const createReport = params => {
  const url = `${config.api.REST_URL}/report/createReport`;
  return apiClient.post(url, params);
};

const getReportSpecs = params => {
  const url = `${config.api.REST_URL}/report/getReportSpecs`;
  return apiClient.post(url, params);
};

const getRptRuns = params => {
  const url = `${config.api.REST_URL}/report/getReportRuns`;
  return apiClient.post(url, params);
};

const splitOrders = params => {
  const url = `${config.api.REST_URL}/trade/splitOrders`;
  return apiClient.post(url, params);
};

const splitSubOrders = params => {
  const url = `${config.api.REST_URL}/trade/splitSubOrders`;
  return apiClient.post(url, params);
};

const loadNonOrderLocates = params => {
  const url = `${config.api.REST_URL}/locate/loadNonOrderLocates`;
  return apiClient.post(url, params);
};

const saveNonOrderLocates = params => {
  const url = `${config.api.REST_URL}/locate/saveNonOrderLocates`;
  return apiClient.post(url, params);
};

const calcNonOrderLocatesContext = params => {
  const url = `${config.api.REST_URL}/locate/calcNonOrderLocatesContext`;
  return apiClient.post(url, params);
};

const getRolloverContracts = params => {
  const url = `${config.api.REST_URL}/trade/getRolloverContracts`;
  return apiClient.post(url, params);
};

const bookRequests = params => {
  const url = `${config.api.REST_URL}/trade/bookRequests`;
  return apiClient.post(url, params);
};

const getTxnLimits = () => {
  const url = `${config.api.REST_URL}/security/getTxnLimits`;
  return apiClient.get(url);
};

const addTxnLimits = data => {
  const url = `${config.api.REST_URL}/security/addTxnLimits`;
  return apiClient.post(url, data);
};

const getMktSuspension = params => {
  const url = `${config.api.REST_URL}/trade/getMktSuspension`;
  return apiClient.post(url, params);
};

const addMktSuspension = data => {
  const url = `${config.api.REST_URL}/trade/addMktSuspension`;
  return apiClient.post(url, data);
};

const removeMktSuspension = data => {
  const url = `${config.api.REST_URL}/trade/removeMktSuspension`;
  return apiClient.post(url, data);
};

const submitMktSuspension = data => {
  const url = `${config.api.REST_URL}/trade/submitMktSuspension`;
  return apiClient.post(url, data);
};

const getPthPos = data => {
  const url = `${config.api.REST_URL}/trade/getPthPos`;
  return apiClient.post(url, data);
};

const saveBrokerRule = data => {
  const url = `${config.api.REST_URL}/trade/saveBrokerRule`;
  return apiClient.post(url, data);
};

const getBrokerRules = data => {
  const url = `${config.api.REST_URL}/trade/getBrokerRules`;
  return apiClient.post(url, data);
};

const getBrokerCommissionReuslt = date => {
  const url = `${config.api.RESEARCH_URL}/brokerCommissionReuslt/${date}`;
  return apiClient.post(url);
};

const applyCommissionResult = data => {
  const url = `${config.api.RESEARCH_URL}/applyCommissionResult`;
  return apiClient.post(url, data);
};

const getExpensiveBorrowRate = data => {
  const url = `${config.api.REST_URL}/pets/get_high_borrow_rate_pos`;
  return apiClient.post(url, data);
};

const getAxePositionRate = data => {
  const url = `${config.api.REST_URL}/pets/get_axe_position_rate`;
  return apiClient.post(url, data);
};

const getScoreCounterpartys = data => {
  const url = `${config.api.REST_URL}/trade/getScoreCounterpartys`;
  return apiClient.post(url, data);
};

const getOrderProduct = data => {
  const url = `${config.api.REST_URL}/trade/getOrderProduct`;
  return apiClient.post(url, data);
};

const locatePricing = data => {
  const url = `${config.api.REST_URL}/trade/locatePricing`;
  return apiClient.post(url, data);
};

const queryHoldings = data => {
  const url = `${config.api.REST_URL}/trade/queryHoldings`;
  return apiClient.post(url, data);
};

const getTargetRatio = data => {
  const url = `${config.api.REST_URL}/trade/getTargetRatio`;
  return apiClient.post(url, data);
};

const getBuMapping = data => {
  const url = `${config.api.REST_URL}/trade/getBuMapping`;
  return apiClient.post(url, data);
};

const client = {
  approveOrders,
  rejectOrders,
  assignOrders,
  archiveOrders,
  runCompliance,
  finalizeOrders,
  addOrders,
  updateOrders,
  activateOrders,
  deleteOrders,
  ackOrderRouteErrors,
  overrideCplResults,
  enrichOrders,

  getOrderBlotter,
  getAuditChanges,
  getTraders,

  calcExecutionInfos,
  calcExecutionContexts,
  getExecutionOptions,
  getVenueServiceInfos,
  submitRoutes,
  sendRoutes,
  cancelRoutes,

  addLocates,
  getLocateOptions,
  calcLocateContexts,
  calcCashLocateContexts,

  getRules,
  getRuleOptions,
  submitRules,

  check,
  getHelp,
  getShortListByTickers,
  getIPOList,
  updateIPOList,

  createReport,
  getReportSpecs,
  getRptRuns,
  splitOrders,
  splitSubOrders,

  loadNonOrderLocates,
  saveNonOrderLocates,
  calcNonOrderLocatesContext,

  getRolloverContracts,
  bookRequests,

  getTxnLimits,
  addTxnLimits,
  getPthPos,

  saveBrokerRule,
  getBrokerRules,
  getBrokerCommissionReuslt,
  applyCommissionResult,

  getExpensiveBorrowRate,
  getAxePositionRate,
  getScoreCounterpartys,

  getMktSuspension,
  addMktSuspension,
  removeMktSuspension,
  submitMktSuspension,

  getOrderProduct,
  locatePricing,
  queryHoldings,
  getTargetRatio,
  getBuMapping
};

export default client;
