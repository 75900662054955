import React, { Component } from 'react';
import { Modal } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import client from '../../api/client';
import _ from 'lodash';

export default class ShowRelPostionDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      defaultColDef: {
        enableCellChangeFlash: true,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true
      },
      columnDefs: [
        {
          field: 'primaryTicker',
          headerName: 'Primary Ticker',
          cellClass: 'keyword',
          width: 180
        },
        {
          field: 'quantityDirection',
          headerName: 'LSF',
          cellClass: 'non-number',
          width: 100,
          enableRowGroup: true,
          rowGroup: true,
          cellClassRules: {
            long: params => params.value === 'LONG',
            short: params => params.value === 'SHORT',
            flat: params => params.value !== 'LONG' && params.value !== 'SHORT'
          }
        }
      ]
    };
  }

  componentDidMount() {
    this._loadData();
  }

  _loadData = () => {
    client
      .getRelPosition()
      .then(resp => {
        this.setState({
          data: resp.data
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  _onGridReady = params => {
    this.gridApi = params.api;
    this.golumnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  };

  _createGrid = () => {
    const { className = 'grid-wrapper' } = this.props;
    const { data } = this.state;
    const sortedData = _.orderBy(data, ['createTime'], ['desc']);
    return (
      <div className={className}>
        <div
          className={`ag-theme-balham-dark ${className}`}
          style={{ height: '100%' }}
        >
          <AgGridReact
            // properties
            defaultColDef={this.state.defaultColDef}
            columnDefs={this.state.columnDefs}
            rowData={sortedData}
            suppressAggFuncInHeader={true}
            animateRows={true}
            pivotPanelShow={false}
            groupDefaultExpanded={-1}
            getRowNodeId={data => data.id}
            rowSelection="single"
            onGridReady={this._onGridReady}
          />
        </div>
      </div>
    );
  };

  render() {
    const { closeModal } = this.props;
    return (
      <Modal
        size="large"
        visible={true}
        title={`My core stock pitch`}
        width={550}
        height={650}
        onCancel={closeModal}
        onOk={closeModal}
        bodyStyle={{ height: '620px', overflow: 'auto' }}
        closable={true}
      >
        {this._createGrid()}
      </Modal>
    );
  }
}
