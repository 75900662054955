import React, { Component } from 'react';
import { HotTable } from '@handsontable/react';
import { Message } from 'semantic-ui-react';
import hotTableUtils from 'common/ui/hotTableUtils';
import {
  addCBTradesGridColumns,
  addEqtyTradesGridColumns
} from './GridColumnMap';
import { Modal, Button, Spin, Select } from 'antd';
import {
  DIALOG_EXECUTE_CB_TRADES,
  DIALOG_EXECUTE_QUANT_TRADES
} from '../../orderConstants';
import Trade from '../../entities/Trade';
import _ from 'lodash';
import client from '../../api/client';
import hyperid from 'hyperid';
import { roleTradeLabel } from 'common/utils/DomainUtils';

const uniqidInstance = hyperid();
const { Option } = Select;
const _createUIOptions = codes => {
  return codes.map(c => (
    <Option key={c || 'default'} value={c}>
      {c}
    </Option>
  ));
};
class CBBatchTradesDialog extends Component {
  constructor(props) {
    super(props);
    this.rowKey = 0;
    const { funds } = this.props.settings;
    const fundBookOptions = [];
    funds.forEach(fundItem => {
      const books = fundItem.books
        ? fundItem.books.filter(r => r.enableTxn)
        : [];
      if (!_.isEmpty(books)) {
        let fundBook = {};
        fundBook.value = fundItem.name;
        fundItem.books.forEach(item => {
          if (item.enableTxn)
            fundBookOptions.push(fundItem.name + '-' + item.name);
        });
      }
    });
    const hideFundBook =
      !funds ||
      !funds.length ||
      (funds.length === 1 && funds[0].books.length <= 1);
    this.state = {
      isLoading: false,
      fundBookOptions: fundBookOptions,
      hideFundBook: hideFundBook,
      submitStatus: 'READY',
      serverErrMsg: '',
      verificationErrs: [],
      verificationEqtyErrs: [],
      selectedCBTrade: null,
      selectedEqtyTrade: null,
      cbTrades: [],
      eqtyTrades: [],
      securityMap: {},
      hotTableSettings: null,
      eqtyHotTableSettings: null,
      liveRiskInfoMap: {},
      gridWrapperStyle: {
        width: '100%',
        height: '350px',
        marginTop: '20px'
      },
      typeList: ['Open', 'Unwind'],
      assetList: ['EQTY', 'CB'],
      type: 'Open',
      asset: this._isCBBook() ? 'CB' : 'EQTY'
    };
  }

  componentDidMount() {
    this._init();
    this._debouncedSubmit = _.debounce(this.onFormSubmit, 500);
  }

  _isCBBook = () => {
    const { user } = this.props.settings;
    if (!_.isEmpty(user)) {
      const { fundBooks, englishName } = user;
      return !_.isEmpty(
        fundBooks.filter(
          r => r.bookManager === englishName && ['T51'].includes(r.bookCode)
        )
      );
    }
    return false;
  };

  _calLiveInfoMap = () => {
    const { liveRiskInfos } = this.props;
    return liveRiskInfos.reduce((acc, curr) => {
      const key = `${curr.fundCode}-${curr.bookCode}`;
      acc[key] = curr;
      return acc;
    }, {});
  };

  _initCBTrades = size => {
    const updateData = [];
    for (let i = 0; i < size; i++) {
      updateData.push(this._addTrade());
    }
    return updateData;
  };

  _initEqtyTrades = size => {
    const updateData = [];
    for (let i = 0; i < size; i++) {
      updateData.push({ ...this._addTrade(), strategy: 'NONE' });
    }
    return updateData;
  };

  _init = () => {
    const data = this._initCBTrades(10);
    const eqtyTrades = this._initEqtyTrades(10);
    const setting = this._createCBGridSettings(this.state.fundBookOptions);
    const eqtySetting = this._createEqtyGridSettings(
      this.state.fundBookOptions
    );
    const liveRiskInfoMap = this._calLiveInfoMap();

    this.setState({
      hotTableSettings: setting,
      eqtyHotTableSettings: eqtySetting,
      cbTrades: data,
      eqtyTrades,
      liveRiskInfoMap
    });
  };

  _createEqtyGridSettings = fundBookOptions => {
    const { info } = this.props;
    const optionsMapping = {
      fundBook: fundBookOptions
    };
    const columns = addEqtyTradesGridColumns.map(c => {
      if (c.data === 'tickerLong' || c.data === 'tickerShort') {
        return {
          ...c,
          type: 'autocomplete',
          source(query, process) {
            if (query.length <= 3) return [];
            client
              .searchTickers(query)
              .then(response => process(response.tickers));
          },
          strict: true
        };
      }
      const values = optionsMapping[c.data];
      return values
        ? {
            ...c,
            source: values
          }
        : c;
    });
    if (info.createNew) {
      return hotTableUtils.createSettings({
        columns: columns,
        rowHeaders: true,
        contextMenu: {
          items: {
            clone: {
              name: 'Clone row',
              callback: () => {
                // Must delay below operation, otherwise handsontable will throw exception.
                _.delay(() => this._cloneEqtyTrade(), 100);
              }
            },
            remove_row: {}
          }
        }
      });
    }
  };

  _cloneEqtyTrade = () => {
    const { selectedEqtyTrade, eqtyTrades } = this.state;
    const row = eqtyTrades.findIndex(r => r.key === selectedEqtyTrade.key);
    const key = `${uniqidInstance()}`;
    const cloneRow = {
      ...selectedEqtyTrade,
      fundBook: null,
      longTradeBeforePct: 0,
      longTradePct: selectedEqtyTrade.longTradeAfterPct,
      shortTradeBeforePct: 0,
      shortTradePct: selectedEqtyTrade.shortTradeAfterPct,
      key
    };
    const updatedEqtyList = [
      ...eqtyTrades.slice(0, row + 1),
      cloneRow,
      ...eqtyTrades.slice(row + 1)
    ];
    _.delay(() => {
      this.setState({ eqtyTrades: updatedEqtyList });
    }, 100);
  };

  _createCBGridSettings = fundBookOptions => {
    const { info } = this.props;
    const optionsMapping = {
      fundBook: fundBookOptions
    };
    const columns = addCBTradesGridColumns.map(c => {
      if (c.data === 'ticker') {
        return {
          ...c,
          type: 'autocomplete',
          source(query, process) {
            if (query.length <= 3) return [];
            client
              .searchTickers(query)
              .then(response => process(response.tickers));
          },
          strict: true
        };
      }
      const values = optionsMapping[c.data];
      return values
        ? {
            ...c,
            source: values
          }
        : c;
    });
    if (info.createNew) {
      return hotTableUtils.createSettings({
        columns: columns,
        rowHeaders: true,
        contextMenu: {
          items: {
            clone: {
              name: 'Clone row',
              callback: () => {
                // Must delay below operation, otherwise handsontable will throw exception.
                _.delay(() => this._cloneCBTrade(), 100);
              }
            },
            remove_row: {}
          }
        }
      });
    } else {
      const updateColumns = columns.map(item => {
        let readOnly = false;
        if (
          item['headerName'] === 'Fund-Book' ||
          item['headerName'] === 'Ticker'
        ) {
          readOnly = true;
        }
        return {
          ...item,
          readOnly
        };
      });
      return hotTableUtils.createSettings({
        columns: updateColumns,
        rowHeaders: true
      });
    }
  };

  _cloneCBTrade = () => {
    const { cbTrades, selectedCBTrade } = this.state;
    const row = cbTrades.findIndex(r => r.key === selectedCBTrade.key);
    const key = `${uniqidInstance()}`;
    const cloneRow = {
      ...selectedCBTrade,
      key
    };
    const updatedCBList = [
      ...cbTrades.slice(0, row + 1),
      cloneRow,
      ...cbTrades.slice(row + 1)
    ];
    _.delay(() => {
      this.setState({ cbTrades: updatedCBList });
    }, 100);
  };

  _afterRemoveRow = () => {
    const { cbTrades } = this.state;
    const verificationErrs = this._validateTrades(cbTrades);
    _.delay(() => {
      this.setState({ verificationErrs });
    }, 100);
  };

  _afterRemoveEqtyRow = () => {
    const { eqtyTrades } = this.state;
    const verificationEqtyErrs = this.validateEqtyTrades(eqtyTrades);
    _.delay(() => {
      this.setState({ verificationEqtyErrs });
    }, 100);
  };

  _createCBTradesGrid = () => {
    const { gridWrapperStyle, hotTableSettings, cbTrades } = this.state;
    return (
      !_.isEmpty(cbTrades) && (
        <div style={gridWrapperStyle}>
          <HotTable
            {...hotTableSettings}
            data={cbTrades}
            beforeChange={this._beforeCellChange}
            rowHeaders={false}
            manualColumnResize={true}
            afterSelectionEnd={this._onRowSelectionChanged}
            afterRemoveRow={this._afterRemoveRow}
          ></HotTable>
        </div>
      )
    );
  };

  _createEqtyTradesGrid = () => {
    const { gridWrapperStyle, eqtyHotTableSettings, eqtyTrades } = this.state;
    return (
      !_.isEmpty(eqtyTrades) && (
        <div style={gridWrapperStyle}>
          <HotTable
            {...eqtyHotTableSettings}
            data={eqtyTrades}
            beforeChange={this._beforeEqtyCellChange}
            rowHeaders={true}
            manualColumnResize={true}
            afterSelectionEnd={this._onEqtyRowSelectionChanged}
            afterRemoveRow={this._afterRemoveEqtyRow}
          ></HotTable>
        </div>
      )
    );
  };

  submitCB = emptyTrade => {
    const { cbTrades, type } = this.state;
    if (_.isEmpty(cbTrades)) {
      return;
    }

    const verificationErrs = this._validateTrades(cbTrades);
    if (!_.isEmpty(verificationErrs)) {
      this.setState({ verificationErrs });
      return;
    }

    const tradeModels = [];
    cbTrades
      .filter(r => !_.isEmpty(r.ticker))
      .forEach(t => {
        const {
          ticker,
          fundBook,
          cbName,
          eqtyName,
          parVal,
          eqtyTicker,
          eqtyLotsize,
          cbLongQty,
          eqtyShortQty,
          strategy
        } = t;

        const [fundCode, bookCode] = fundBook.split('-');
        const basket = `${ticker}-${uniqidInstance()}`;
        const cbSide = type === 'Open' ? 'BUY' : 'SELL';
        const tickerSide = type === 'Open' ? 'SHRT' : 'COVR';
        const cbLongFields = {
          ...emptyTrade,
          basket,
          strategy,
          ticker,
          fundCode,
          bookCode,
          quantity: cbLongQty,
          side: cbSide,
          lotSize: parVal
        };

        const cbLongSecurity = {
          securityCode: cbName
        };

        const eqtyShortFields = {
          ...emptyTrade,
          basket,
          ticker: eqtyTicker,
          strategy,
          fundCode,
          bookCode,
          quantity: eqtyShortQty,
          side: tickerSide,
          lotSize: eqtyLotsize
        };

        const eqtyShortSecurity = {
          securityCode: eqtyName
        };

        tradeModels.push(Trade.toModel(cbLongFields, cbLongSecurity, false));
        tradeModels.push(
          Trade.toModel(eqtyShortFields, eqtyShortSecurity, false)
        );
      });
    return tradeModels;
  };

  submitEqty = emptyTrade => {
    const { eqtyTrades } = this.state;

    if (_.isEmpty(eqtyTrades)) {
      return;
    }

    const verificationEqtyErrs = this.validateEqtyTrades(eqtyTrades);
    if (!_.isEmpty(verificationEqtyErrs)) {
      this.setState({ verificationEqtyErrs });
      return;
    }

    const tradeModels = [];
    this._buildEqtyTrades(eqtyTrades, tradeModels, emptyTrade);
    return tradeModels;
  };

  onFormSubmit = () => {
    const { cbTrades, eqtyTrades, asset } = this.state;

    if (_.isEmpty(cbTrades) && _.isEmpty(eqtyTrades)) {
      this.closeDialog();
      return;
    }

    const emptyTrade = {
      ...Trade.emptyForm('BASKET'),
      pmReason: 'Pair Trade'
    };
    let tradeModels = [];

    if (asset === 'EQTY') {
      tradeModels = this.submitEqty(emptyTrade);
    } else {
      tradeModels = this.submitCB(emptyTrade);
    }

    if (!_.isEmpty(tradeModels)) {
      const trades = tradeModels.map(r => ({
        ...r,
        algoCode: emptyTrade.algoCode
      }));
      this.openQuantTradesDialog(trades, this.closeDialog);
    }
  };

  _buildEqtyTrades = (eqtyTrades, tradeModels, emptyTrade) => {
    eqtyTrades
      .filter(r => !_.isEmpty(r.tickerLong))
      .forEach(r => {
        const {
          tickerLong,
          fundBook,
          longTradeAfterPct,
          tickerShort,
          shortTradeAfterPct,
          strategy
        } = r;
        const [fundCode, bookCode] = fundBook.split('-');
        const basket = `${r.tickerShort}-${uniqidInstance()}`;
        const longTrade = {
          ...emptyTrade,
          basket,
          strategy,
          ticker: tickerLong,
          fundCode,
          bookCode,
          posnEndPct: longTradeAfterPct,
          side: 'BUY'
        };

        const longSecurity = {
          securityCode: tickerLong
        };

        const shortTrade = {
          ...emptyTrade,
          basket,
          strategy,
          ticker: tickerShort,
          fundCode,
          bookCode,
          posnEndPct: shortTradeAfterPct,
          side: 'SHRT'
        };

        const shortSecurity = {
          securityCode: tickerShort
        };

        tradeModels.push({
          ...Trade.toModel(longTrade, longSecurity, false),
          posnEndPct: longTradeAfterPct,
          quantity: null,
          qtyUsd: null,
          qtyPct: null,
          qtyInput: null
        });
        tradeModels.push({
          ...Trade.toModel(shortTrade, shortSecurity, false),
          posnEndPct: shortTradeAfterPct,
          quantity: null,
          qtyUsd: null,
          qtyPct: null,
          qtyInput: null
        });
      });
  };

  openQuantTradesDialog = (trades, callback) => {
    this.props.openDialog(DIALOG_EXECUTE_QUANT_TRADES, {
      createNew: true,
      trades,
      callback
    });
  };

  _beforeCellChange = async (changes, source) => {
    let { cbTrades } = this.state;
    let trades = cbTrades;
    let updatedTrades = await Promise.all(
      changes.map(async c => {
        const [row, field, oldValue, newValue] = c;
        if (oldValue === newValue) return {};
        const trade = cbTrades[row];
        if (field === 'fundBook' && newValue) {
          const fundBookArr = newValue.split('-');
          trade['fundCode'] = fundBookArr[0];
          trade['bookCode'] = fundBookArr[1];
        }
        return await this._calcTrade(trade, field, newValue);
      })
    );

    updatedTrades = updatedTrades.filter(r => !_.isEmpty(r));
    const updatedTradeMap = _.keyBy(updatedTrades, ut => ut.key);
    trades = trades.map(t => {
      const ut = updatedTradeMap[t.key];
      return ut || t;
    });

    const errors = this._validateTrades(updatedTrades);

    _.delay(() => {
      this.setState({
        cbTrades: trades,
        verificationErrs: errors
      });
    }, 100);

    return false;
  };

  _calcTrade = async (trade, name, value) => {
    const { securityMap } = this.state;
    if (name === 'strategy' && _.isEmpty(value)) {
      value = _.toUpper(value);
    }
    let result = {
      ...trade,
      [name]: value
    };
    if (name === 'ticker' && !_.isNull(value)) {
      let cbInfos = [];
      try {
        cbInfos = await client.getCBInfos([{ ticker: value }]);
      } catch (err) {
        console.log(err);
      }
      if (!_.isEmpty(cbInfos)) {
        result = {
          ...trade,
          ...cbInfos[0]
        };
        securityMap[value] = cbInfos[0];
      }
    }

    if (
      ['parity', 'fundBook', 'premium', 'eqtyShortQty', 'strategy'].includes(
        name
      )
    )
      return result;
    const {
      parVal,
      price,
      delta,
      cvtPrice,
      eqtyPrice,
      eqtyLotsize,
      cbLongQty
    } = result;
    const newCvtPrice = cvtPrice ? _.floor(cvtPrice, 4) : 0;
    const parity = (parVal / newCvtPrice) * eqtyPrice;
    const premium = (parVal * price) / 100 / parity - 1;
    const eqtyShortQty =
      Math.floor((cbLongQty * delta) / newCvtPrice / eqtyLotsize) * eqtyLotsize;
    return { ...result, parity, premium, eqtyShortQty };
  };

  _beforeEqtyCellChange = async (changes, source) => {
    let { eqtyTrades } = this.state;
    let trades = eqtyTrades;
    let updatedTrades = await Promise.all(
      changes.map(async c => {
        const [row, field, oldValue, newValue] = c;
        if (oldValue === newValue) return {};
        const trade = eqtyTrades[row];
        if (field === 'fundBook' && newValue) {
          const fundBookArr = newValue.split('-');
          trade['fundCode'] = fundBookArr[0];
          trade['bookCode'] = fundBookArr[1];
        }
        return await this._calcEqtyTrade(trade, field, newValue);
      })
    );

    updatedTrades = updatedTrades.filter(r => !_.isEmpty(r));
    const updatedTradeMap = _.keyBy(updatedTrades, ut => ut.key);
    trades = trades.map(t => {
      const ut = updatedTradeMap[t.key];
      return ut || t;
    });

    const errors = this.validateEqtyTrades(updatedTrades);

    _.delay(() => {
      this.setState({
        eqtyTrades: trades,
        verificationEqtyErrs: errors
      });
    }, 100);

    return false;
  };

  _calcEqtyTrade = async (trade, name, value) => {
    const { securityMap } = this.state;
    if (name === 'strategy' && _.isEmpty(value)) {
      value = _.toUpper(value);
    }
    let result = {
      ...trade,
      [name]: value
    };
    if (['tickerLong', 'tickerShort'].includes(name) && !_.isNull(value)) {
      const tickerField = name === 'tickerLong' ? 'tickerLong' : 'tickerShort';
      const infos = await client.getSecurities([{ ticker: value }]);
      if (!_.isEmpty(infos) && !_.isEmpty(infos[0])) {
        result = {
          ...trade,
          [tickerField]: infos[0]['ticker']
        };
        securityMap[value] = infos[0];
        result = this.tickerChangeHandle(infos, result, name);
      }
    }
    result = this.fundBookStragetyChangeHandle(result, name);
    if (name === 'longTradeAfterPct') {
      const {
        longTradeAfterPct = 0,
        longTradeBeforePct = 0,
        shortTradeBeforePct = 0
      } = result;
      result = {
        ...result,
        longTradePct: longTradeAfterPct - longTradeBeforePct
      };
      if (result.tickerShort) {
        result = {
          ...result,
          shortTradePct: longTradeAfterPct - shortTradeBeforePct,
          shortTradeAfterPct: -longTradeAfterPct
        };
      }
    }

    if (name === 'tickerShort' && result['longTradeAfterPct']) {
      result = {
        ...result,
        shortTradeAfterPct: -result['longTradeAfterPct']
      };
    }
    return { ...result };
  };

  fundBookStragetyChangeHandle = (result, name) => {
    if (name !== 'fundBook' && name !== 'strategy') return result;
    const {
      tickerLong,
      longTradeAfterPct = 0,
      tickerShort,
      shortTradeAfterPct = 0
    } = result;
    const { fundBooks } = this.props.settings;
    const { liveRiskInfoMap, securityMap } = this.state;
    const ctxLong = this._buildCalcCtx(
      result,
      securityMap[tickerLong],
      liveRiskInfoMap,
      fundBooks
    );
    let startPctLong = 0,
      startPctShort = 0;
    if (ctxLong) startPctLong = this._calcPosnStartPct(ctxLong);
    const ctxShort = this._buildCalcCtx(
      result,
      securityMap[tickerShort],
      liveRiskInfoMap,
      fundBooks
    );
    if (ctxShort) startPctShort = this._calcPosnStartPct(ctxShort);
    return {
      ...result,
      longTradeBeforePct: startPctLong,
      longTradePct: longTradeAfterPct - startPctLong,
      shortTradeBeforePct: startPctShort,
      shortTradePct: shortTradeAfterPct - startPctShort
    };
  };

  tickerChangeHandle = (infos, result, name) => {
    const { fundBooks } = this.props.settings;
    const { liveRiskInfoMap } = this.state;
    const ctx = this._buildCalcCtx(
      result,
      infos[0],
      liveRiskInfoMap,
      fundBooks
    );
    const startPct = this._calcPosnStartPct(ctx);
    if (name === 'tickerLong') {
      return {
        ...result,
        longTradeBeforePct: startPct,
        longTradePct: 0,
        longTradeAfterPct: startPct
      };
    } else {
      const { longTradeAfterPct = 0 } = result;
      return {
        ...result,
        shortTradeBeforePct: startPct,
        shortTradePct: longTradeAfterPct - startPct,
        shortTradeAfterPct: longTradeAfterPct
      };
    }
  };

  _calcPosnStartPct = ctx => {
    const {
      usdNav,
      usdValuePerShare,
      theoreticalPosn,
      theoreticalPosnForClose
    } = ctx;

    const openFlag = true;
    const posStart = openFlag ? theoreticalPosn : theoreticalPosnForClose;

    return _.round(
      usdNav ? ((posStart * usdValuePerShare) / usdNav) * 100 : 0,
      4
    );
  };

  _buildCalcCtx = (trade, security, riskInfoMap, fundBooks) => {
    const { fundBook, strategy } = trade;
    const riskKey = fundBook;
    const masterKey = _.get(fundBooks, [riskKey, 'masterFundBook']);
    const risk = riskInfoMap[riskKey] || riskInfoMap[masterKey];

    if (!security || !risk) {
      return null;
    }

    const {
      securityType2,
      undlPrice,
      price,
      usdFxRate,
      lotSize = 1,
      priceMultiplier = 1
    } = security;

    const { usdNav } = risk;

    // Use market price even when place limit order.
    // Yet for IPO trade, the price is null, thus use limit price instead.
    // For option, just use undl price instead of price.
    const usdValuePerShare =
      (securityType2 === 'Option' ? undlPrice : price || 0) *
      priceMultiplier *
      usdFxRate;

    const posKey = `${fundBook}-${strategy}`;
    const { theoreticalPosn = 0, theoreticalPosnForClose = 0 } =
      security.positions[posKey] || {};

    return {
      usdNav,
      lotSize,
      usdValuePerShare,
      theoreticalPosn,
      theoreticalPosnForClose
    };
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus } = this.state;
    return {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      )
    }[submitStatus];
  };

  _addTrade = () => {
    const { fundBookOptions } = this.state;
    let fundBook = null;
    if (!_.isEmpty(fundBookOptions)) {
      fundBook = fundBookOptions[0];
    }
    return { key: uniqidInstance(), fundBook };
  };

  _validateTrades = trades => {
    const errors = [];
    trades.forEach(t => {
      const e = this._validateCBTrades(t);
      if (!_.isEmpty(e)) {
        errors.push(e);
      }
    });
    return errors;
  };

  _validateCBTrades = t => {
    const fields = ['fundBook', 'ticker', 'eqtyTicker', 'strategy'];
    const numberFields = [
      'parVal',
      'price',
      'delta',
      'cvtPrice',
      'eqtyPrice',
      'parity',
      'premium',
      'cbLongQty',
      'eqtyShortQty'
    ];
    if (_.isEmpty(t.ticker)) return null;
    const errors = fields
      .map(r => (_.isEmpty(t[r]) ? r : null))
      .filter(r => !_.isEmpty(r));
    const numberFieldErrors = numberFields
      .map(r => (_.isNil(t[r]) || _.isNaN(t[r]) ? r : null))
      .filter(r => !_.isEmpty(r));
    const errorFields = [...errors, ...numberFieldErrors].map(r =>
      this._getFieldName(r)
    );
    if (!_.isEmpty(errorFields))
      return `[${t.ticker}]${errorFields.join(',')} can't be null`;
    return null;
  };

  validateEqtyTrades = trades => {
    const errors = [];
    trades.forEach((t, index) => {
      const { tickerLong, tickerShort } = t;
      if (!_.isEmpty(tickerLong) || !_.isEmpty(tickerShort)) {
        const errorMsg = ['tickerLong', 'tickerShort', 'fundBook', 'strategy']
          .map(r => (_.isEmpty(t[r]) ? r : null))
          .filter(r => !_.isEmpty(r));
        if (!_.isEmpty(errorMsg))
          errors.push(`[${index}]${errorMsg.join(',')} can't be null`);
      }
    });
    return errors;
  };

  _getFieldName = field => {
    const filterData = addCBTradesGridColumns.filter(r => r.data === field);
    if (!_.isEmpty(filterData)) {
      const header = filterData[0].headerName;
      if (header.startsWith('<span')) {
        return filterData[0].data;
      }
      return header;
    }
    return field;
  };

  _createErrorsPanel = () => {
    const { verificationErrs, verificationEqtyErrs, asset } = this.state;
    const errors = asset === 'EQTY' ? verificationEqtyErrs : verificationErrs;
    return (
      !_.isEmpty(errors) && (
        <Message error header="Fix Below Errors" list={errors} />
      )
    );
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_EXECUTE_CB_TRADES);
  };

  _onRowSelectionChanged = row => {
    const { cbTrades, selectedCBTrade } = this.state;
    const cbTradeRow = cbTrades[row];
    if (cbTradeRow === selectedCBTrade) return;
    this.setState({
      selectedCBTrade: cbTradeRow
    });
  };

  _onEqtyRowSelectionChanged = row => {
    const { eqtyTrades, selectedEqtyTrade } = this.state;
    const eqtyTradeRow = eqtyTrades[row];
    if (eqtyTradeRow === selectedEqtyTrade) return;
    this.setState({
      selectedEqtyTrade: eqtyTradeRow
    });
  };

  _onInputChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  _createOptions = () => {
    const { type, asset, typeList, assetList } = this.state;
    return (
      <div>
        <Select
          style={{ marginRight: '5px', width: '150px' }}
          value={asset}
          size="small"
          onChange={value => {
            this._onInputChange('asset', value);
          }}
        >
          {_createUIOptions(assetList)}
        </Select>
        {asset !== 'EQTY' && (
          <Select
            style={{ marginRight: '5px', width: '150px' }}
            value={type}
            size="small"
            onChange={value => {
              this._onInputChange('type', value);
            }}
          >
            {_createUIOptions(typeList)}
          </Select>
        )}
      </div>
    );
  };

  render() {
    const { isLoading, asset } = this.state;
    const { user = {} } = this.props.settings;
    const title = `New Pair ${roleTradeLabel(user)}`;
    return (
      <Modal
        width={1300}
        maskClosable={false}
        title={title}
        visible={true}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        footer={[
          this._createSubmitBtn(this._debouncedSubmit),
          <Button key="close" type="primary" onClick={this.closeDialog}>
            Close
          </Button>
        ]}
      >
        <Spin tip="Loading..." spinning={isLoading}>
          {this._createOptions()}
          {asset === 'EQTY'
            ? this._createEqtyTradesGrid()
            : this._createCBTradesGrid()}
          {this._createErrorsPanel()}
        </Spin>
      </Modal>
    );
  }
}

export default CBBatchTradesDialog;
