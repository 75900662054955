import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import numeral from 'numeral';
import { moneyFormatter } from 'common/utils/valueFormatters';
import { numberStyle } from 'common/utils/styleChooser';
import agGridUtils from '../../../common/ui/agGridUtils';
import { Button, DatePicker, Spin, Select } from 'antd';
import client from '../api/client';
import moment from 'moment';
import ExposureReviewDialog from './exposure/ExposureReviewDialog';
import _ from 'lodash';

const { Option } = Select;
const _createUIOptions = codes => {
  return codes.map(c => (
    <Option key={c || 'default'} value={c}>
      {c}
    </Option>
  ));
};
class TreasuryReportGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agGridSettings: agGridUtils.createSettings({
        defaultColDef: {
          enableCellChangeFlash: true,
          minWidth: 25,
          sortable: true,
          filter: true,
          resizable: true
        },
        rowSelection: 'single',
        floatingFilter: false,
        groupIncludeTotalFooter: false
      }),
      autoGroupColumnDef: {
        cellClass: 'keyword',
        lockPinned: true
        // field: 'pairName',
        // headerName: 'Name',
      },
      loadingStatus: false,
      exportingStatus: false,
      reportData: {},
      queryParams: {
        date: moment()
          .subtract(1, 'days')
          .format('YYYY-MM-DD'),
        fund: 'PMSF',
        view: 'FundCcy'
      },
      fundList: [
        'PMSF',
        'VCC',
        'CVF',
        'SLHL',
        'COP',
        'DCL',
        'PCF',
        'MTP',
        'ZJNF'
      ],
      viewList: ['FundCcy', 'Fund'],
      defaultGroupCol: ['Fund', 'Broker'],
      sum: 0,
      reviewModalData: {
        show: false,
        selectedData: {}
      }
    };
  }

  componentDidMount() {
    this._loadData();
  }

  onGridReady = params => {
    this.gridApi = params.api;
  };

  dateOnChange = (date, dateString) => {
    const { queryParams } = this.state;
    this.setState({
      queryParams: {
        ...queryParams,
        date: dateString
      }
    });
  };

  _loadData = isDownload => {
    const { queryParams } = this.state;
    if (!isDownload) {
      this.setState({
        loadingStatus: true
      });
      client
        .createReport({ params: queryParams })
        .then(resp => {
          this.setState({
            reportData: resp ? resp : {},
            loadingStatus: false
          });
        })
        .catch(err => {
          console.log(err);
          this.setState({
            loadingStatus: false
          });
        });
    } else {
      this.setState({
        exportingStatus: true
      });
      client
        .createReport({ params: queryParams, download: isDownload })
        .then(resp => {
          this.setState({
            exportingStatus: false
          });
        })
        .catch(err => {
          console.log(err);
          this.setState({
            exportingStatus: false
          });
        });
    }
  };

  getData = () => {
    const { views } = this.state.reportData;
    const { view } = this.state.queryParams;
    if (views && views[view]) {
      const { columns, data } = views[view];
      const flatColumns = [];
      columns.forEach(e => {
        flatColumns.push(...e);
      });
      const dataList = this._buildData(flatColumns, data).filter(r => {
        const filterItems = Object.values(r).filter(
          e => _.isNumber(e) && Math.abs(e) >= 100
        );
        return filterItems && filterItems.length > 0;
      });
      const columnList = this._buildColumns(flatColumns, dataList);
      return {
        columns: columnList,
        data: dataList
      };
    }
    return {
      columns: [],
      data: []
    };
  };

  _getType = data => {
    const filterData = data.filter(r => r);
    if (_.isEmpty(filterData)) {
      return undefined;
    }
    if (_.isNumber(filterData[0])) {
      return 'n';
    } else if (_.isString(filterData[0])) {
      return 's';
    }
  };

  _buildColumns = (columns, data) => {
    const { defaultGroupCol } = this.state;
    return columns.map(r => {
      const valueList = data.map(e => e[r]);
      const type = this._getType(valueList);
      const headerName =
        r.startsWith('Long') || r.startsWith('Short')
          ? r
              .replace('Long', 'L')
              .replace('Short', 'S')
              .replace(' ', '-')
          : r;
      let field = {
        field: r,
        headerName,
        cellClass: defaultGroupCol.includes(r)
          ? 'keyword'
          : type === 'n'
          ? 'number'
          : 'non-number',
        rowGroup: defaultGroupCol.includes(r),
        enableRowGroup: type === 's',
        hide: type === undefined || defaultGroupCol.includes(r),
        width: type === 'n' ? 110 : 90
      };
      if (type === 'n') {
        field = {
          ...field,
          aggFunc: 'sum',
          cellClassRules: numberStyle(),
          valueFormatter: moneyFormatter
        };
      }
      return field;
    });
  };

  _buildData = (columns, data) => {
    return data.map(r => _.zipObject(columns, r));
  };

  _onInputChange = ({ name, value }) => {
    const { queryParams } = this.state;
    this.setState({
      queryParams: {
        ...queryParams,
        [name]: value
      }
    });
  };

  _openReviewModal = data => {
    const { reviewModalData } = this.state;
    const selectedData = {
      fields: ['Net (no FX)']
    };
    if (data.aggData) {
      if (data.field === 'Broker') {
        selectedData['custodianList'] = [data.key];
      }
    }
    this.setState({
      reviewModalData: {
        ...reviewModalData,
        show: true,
        selectedData
      }
    });
  };

  _closeReviewModal = () => {
    const { reviewModalData } = this.state;
    this.setState({
      reviewModalData: {
        ...reviewModalData,
        show: false
      }
    });
  };

  _createQueryHeader = () => {
    const { fundList, viewList, loadingStatus, exportingStatus } = this.state;
    const { date, fund, view } = this.state.queryParams;
    return (
      <div style={{ textAlign: 'right' }}>
        <DatePicker
          style={{ marginRight: '5px', width: '200px' }}
          allowClear={false}
          format={'YYYY-MM-DD'}
          value={moment(date, 'YYYY-MM-DD')}
          size="small"
          onChange={this.dateOnChange}
        />
        <Select
          style={{ marginRight: '5px', width: '150px' }}
          value={fund}
          size="small"
          onChange={value => {
            this._onInputChange({
              name: 'fund',
              value: value
            });
          }}
        >
          {_createUIOptions(fundList)}
        </Select>
        <Select
          style={{ marginRight: '5px', width: '150px' }}
          value={view}
          size="small"
          onChange={value => {
            this._onInputChange({
              name: 'view',
              value: value
            });
          }}
        >
          {_createUIOptions(viewList)}
        </Select>
        <Button
          type="primary"
          size="small"
          style={{ marginRight: '5px' }}
          disabled={loadingStatus}
          onClick={() => {
            this._loadData(false);
          }}
        >
          {loadingStatus ? 'Loading' : 'Reload'}
        </Button>
        <Button
          type="primary"
          size="small"
          disabled={exportingStatus}
          style={{ marginRight: '5px' }}
          onClick={() => {
            this._loadData(true);
          }}
        >
          {exportingStatus ? 'Exporting' : 'Export'}
        </Button>
      </div>
    );
  };

  _onRangeSelectionChanged = () => {
    const gridApi = this.gridApi;
    const cellRanges = gridApi.getCellRanges();
    if (cellRanges) {
      let sum = 0;
      cellRanges.forEach(function(range) {
        // get starting and ending row, remember rowEnd could be before rowStart
        const startRow = Math.min(
          range.startRow.rowIndex,
          range.endRow.rowIndex
        );
        const endRow = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
        for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
          // eslint-disable-next-line no-loop-func
          range.columns.forEach(column => {
            const rowModel = gridApi.getModel();
            const rowNode = rowModel.getRow(rowIndex);
            const value = gridApi.getValue(column, rowNode);
            if (_.isNumber(value)) {
              sum += value;
            }
          });
        }
      });
      this.setState({
        sum
      });
    }
  };

  _getContextMenuItems = params => {
    if (params.node == null) return [];

    const menuItems = [
      {
        name: 'Review',
        action: () => this._openReviewModal(params.node)
      }
    ];

    // Add built-in menu items.
    return menuItems;
  };

  _createGrid = () => {
    const { className = 'grid-wrapper' } = this.props;
    const { agGridSettings, loadingStatus } = this.state;

    const { columns, data } = this.getData();
    const screenHeight = window.screen.height;
    const gridDivHeight = `${((screenHeight - 120) / screenHeight) * 100}%`;
    return (
      <>
        {loadingStatus ? (
          <div
            style={{ height: '100%', textAlign: 'center', marginTop: '50px' }}
          >
            <Spin />
          </div>
        ) : (
          <div
            className={`ag-theme-balham-dark ${className}`}
            style={{
              height: gridDivHeight
            }}
          >
            <AgGridReact
              // properties
              rowData={data}
              {...agGridSettings}
              columnDefs={columns}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              groupDefaultExpanded={1}
              animateRows={false}
              deltaRowDataMode={false}
              onRangeSelectionChanged={this._onRangeSelectionChanged}
              // events
              onGridReady={this.onGridReady}
              getContextMenuItems={this._getContextMenuItems}
            />
          </div>
        )}
      </>
    );
  };

  _createSummaryPannel = () => {
    const { sum } = this.state;
    return (
      <div style={{ textAlign: 'right' }}>
        <span
          style={{
            color: '#0099CC',
            marginLeft: '10px',
            fontSize: '15px',
            fontWeight: 'bold'
          }}
        >
          Summary:{' '}
        </span>
        <span
          style={{
            color: '#969696',
            fontSize: '15px',
            fontWeight: 'bold'
          }}
        >
          {numeral(sum).format('0,0')}
        </span>
      </div>
    );
  };

  _createExposureReviewDialog = () => {
    const { views } = this.state.reportData;
    const { date, fund } = this.state.queryParams;
    const { show, selectedData } = this.state.reviewModalData;
    if (_.isEmpty(views)) return;
    const { columns, data } = views['FundCcy'];
    const flatColumns = [];
    columns.forEach(e => {
      if (!['Fund', 'Broker', 'CCY'].includes(e[0])) flatColumns.push(...e);
    });
    const custodianList = [],
      ccyList = [];
    data.forEach(r => {
      const broker = r[1];
      const ccy = r[2];
      if (!custodianList.includes(broker)) custodianList.push(broker);
      if (!ccyList.includes(ccy)) ccyList.push(ccy);
    });
    const params = {
      date,
      fund,
      selectedData,
      selectOptions: {
        fund: this.state.fundList,
        custodian: custodianList,
        ccy: ccyList,
        fields: flatColumns
      }
    };
    return (
      show && (
        <ExposureReviewDialog
          data={params}
          closeDialog={this._closeReviewModal}
        />
      )
    );
  };

  render() {
    return (
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        {this._createQueryHeader()}
        {this._createGrid()}
        {this._createSummaryPannel()}
        {this._createExposureReviewDialog()}
      </div>
    );
  }
}

export default TreasuryReportGrid;
